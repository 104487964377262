import { fsize } from './../../shared/enums/fileSize.enum';
import { FichierEnveloppe } from './../models/fichierEnveloppe.model';
import { GroupItem } from '../models/groupItem.model';
import { MsRightItem, PaToken } from '../models/api/responses/authenticate.response';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionStorage } from '../../shared/enums/session-storage.enum';
import { HttpParams } from '@angular/common/http';
import { TokenResponse } from '../models/api/responses/authenticate.response';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {
  DC_CONST,
  DC_DEPOT_CONST,
  DEPOT_CONST,
} from '../models/constant-variable/depots.model';
import { ROLE, ROLE_OE, ROLE_PA } from '../models/constant-variable/role.model';
import { RedirectMsV3Helper } from '../helpers/redirectMsv3.helper';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private readonly router: Router, private readonly redirectionHelper: RedirectMsV3Helper) { }

  /**
   * Check if the result is parsable before.
   */
  JsonParse(item: string | null): any {
    return item ? JSON.parse(item) : null;
  }
  /**
   * Get the current language from window.sessionStorage.
   * @returns string
   */
  get lang(): string {
    return (
      this.getSessionStorage(SessionStorage.LANG) ||
      (this.getBrowserLanguage() || environment.lang.toLowerCase()).substring(
        0,
        2
      )
    );
  }

  get gestionnaire(): string | null {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .user.gestionnaire;
  }

  get paListFromToken(): PaToken[] {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true).profile
      .pa_list;
  }

  get tokenLocalStorage(): string | null {
    return this.getLocalStorage('session', true)?.t ?? null;
  }

  /**
   * get the current language in locale format, default is fr_FR
   */
  getLocale(): string {
    const SESSION_PER_LANG: any = {
      en: 'en_US',
      fr: 'fr_FR',
    };
    return SESSION_PER_LANG[this.lang] || SESSION_PER_LANG.fr;
  }

  /**
   * get the current language in locale format, default is fr_FR
   */
  getLocaleForIntlDate(): string {
    const SESSION_PER_LANG: any = {
      en: 'en-US',
      fr: 'fr-FR',
    };
    return SESSION_PER_LANG[this.lang] || SESSION_PER_LANG.fr;
  }

  get cleUtilisateur(): string | undefined {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true).profile
      .user.id_ident;
  }

  get canUseGroup(): boolean {
    // return true;
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile.user.groupe;
  }

  get canUseDeals(): boolean {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true).profile.user.affaire === 1;
  }

  /**
   *
   * @param plateforme marches-securise 'ms' ou atetstation-securises 'sts'
   * @returns TokenGroup
   */
  public getGroups(plateforme: 'ms' | 'atst'): GroupItem[] {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .groups[plateforme];
  }

  /**
   *
   * @returns nom de l'entreprise
   */
  public getEntrepriseName(): string {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .user.nom_entreprise;
  }

  /**
   *
   * @returns nom de l'établissement
   */
  public getEtablissementName(): string {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .user.nom_etablissement;
  }

  public siretPA(): number {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .user.siret;
  }

  /**
   *
   * @returns any
   */
  get msRights(): MsRightItem {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .ms_rights;
  }

  public getPaList(): PaToken[] {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
        ?.pa_list ?? []
    );
  }

  /**
   *
   * @returns MsRightItem
   */
  public getTenderRights(): string {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile
      .rights.tender;
  }

  /**
   * get the timezone from token
   */
  get timezone(): string {
    const value = this.getSessionStorage(SessionStorage.USER_SESSION, true)?.timezone
    return _.isEmpty(value) ? 'Europe/Paris' : value;
  }

  /**
   * @description Get value in local storage.
   * @param name ATS of local storage attribute.
   * @param parse true if parse is needed
   * @returns Value of local storage.
   */
  getSessionStorage(name: string, parse: boolean = false): any {
    try {
      return parse
      ? this.JsonParse(window.sessionStorage.getItem('ATS:' + name))
      : window.sessionStorage.getItem('ATS:' + name);
    } catch {
      return null;
    }
  }

  /**
   * @description Get value in local storage.
   * @param name ATS of local storage attribute.
   * @param parse true if parse is needed
   * @returns Value of local storage.
   */
  getLocalStorage(name: string, parse: boolean = false): any {
    try {
      return parse
        ? this.JsonParse(window.localStorage.getItem('ATS:' + name))
        : window.localStorage.getItem('ATS:' + name);
    } catch {
      return null;
    }
  }

  getCookies(): string {
    return this.getLocalStorage('cookies');
  }

  /**
   * Chekc si l'utilisateur en cours est connecté et identifié via sa compte personnel
   * @returns true si l'utilisateurest autentifié
   */
  get isLoggedWithAuthorizationCode(): boolean {
    if (window.sessionStorage.getItem('ATS:session')) {
      return this.JsonParse(window.sessionStorage.getItem('ATS:session')).user_id !==
        null
        ? true
        : false;
    }
    return false;
  }

  get hasFirstLevelOfValidation(): boolean {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)
      ?.validation_1st_level && (this.msRights.D_VALIDATION_DCE || this.msRights.D_MODIF_NIVEAU_1);
  }

  get hasSecondLevelOfValidation(): boolean {
    return this.getSessionStorage(SessionStorage.USER_SESSION, true)
      ?.validation_2nd_level && (this.msRights.D_VALIDATION_DCE_2 || this.msRights.D_MODIF_NIVEAU_2);
  }

  /**
   * Set value in session storage.
   * @param name "ATS:" of session storage attribute.
   * @param  value Value of item to set.
   * @param stringify if needed, false is default
   * @returns void
   */
  setSessionStorage(name: string, value: any, stringify: boolean = false): any {
    window.sessionStorage.setItem(
      'ATS:' + name,
      stringify ? JSON.stringify(value) : value
    );
  }

  /**
   * Set value in session storage.
   * @param name "ATS:" of session storage attribute.
   * @param  value Value of item to set.
   * @param stringify if needed, false is default
   * @returns void
   */
  setLocalStorage(name: string, value: any, stringify: boolean = false): any {
    localStorage.setItem(
      'ATS:' + name,
      stringify ? JSON.stringify(value) : value
    );
  }

  /**
   * Remove value in session storage.
   * @param name ATS of session storage attribute.
   * @returns void
   */
  removeSessionStorage(name: string): void {
    window.sessionStorage.removeItem('ATS:' + name);
  }

  /**
   * Clear all the SessionStorage.
   * @returns void
   */
  clearSessionStorage(): void {
    window.sessionStorage.clear();
  }

  /**
   * Clear all the localstorage
   * @returns void
   */
  clearLocalStrorage(): void {
    localStorage.clear();
  }

  /**
   * @description Get current user language from he browser
   * @returns the navigator language {string}
   */
  getBrowserLanguage(): string {
    return navigator.language;
  }

  /**
   * Get a query parameter value from a url
   * @param url the full url that contains the parameter
   * @param paramName the parameter name
   */
  getParamValueQueryString(url: string, paramName: string): string | null {
    let paramValue = null;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  get token() {
    return this.getSessionStorage('session', true)
      ? this.getSessionStorage('session', true).access_token
      : null;
  }

  /**
   * Get cle etab of user
   * @returns cle etab if is founded or null
   */
  get cleEtab(): string | null {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.user
        ?.cle_etab || null
    );
  }

  /**
   * Get cleAnnonceur
   * @returns cleAnnonceur if is founded or null
   */
  get cleAnnonceur(): string | null {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.pa_list[0]
        ?.cle_annonceur || null
    );
  }

  /**
   * Get clePa
   * @returns clePa if is founded or null
   */
  get clePa(): string | null {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.pa_list[0]
        ?.cle_pa || null
    );
  }

  /**
   * Get cle entreprise of user
   * @returns cle etab if is founded or null
   */
  get cleEnt(): string | null {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.user
        ?.entreprise || null
    );
  }

  // TODO

  get cleEntMs(): string | null {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.user
        ?.entreprise_ms || null
    );
  }

  /**
   * Contrôle la validité du token
   * @returns true si le token est valide
   */
  isTokenValid(): boolean {
    const tokenData: TokenResponse = this.getSessionStorage(
      SessionStorage.USER_SESSION,
      true
    );
    const token = this.getLocalStorage(SessionStorage.USER_SESSION, true)?.t;

    if (!token || !tokenData) return false;

    const today = new Date().getTime();
    if (!_.isUndefined(tokenData.expires)) {
      const tokenExpireDate = new Date(tokenData.expires * 1000).getTime();
      return tokenExpireDate > today;
    }
    return tokenData.expires_in * 1000 > 0;
  }

  /**
   * convert Base64 to a file
   */
  getFileFromBase64(base64: string, mimeType: string, name: string): File {
    return new File([this.dataURItoBlob(base64)], name, { type: mimeType });
  }

  /**
   * from https://stackoverflow.com/questions/58502673/angular-8-parsing-base64-to-file
   * @param dataURI data
   * @returns blob
   */
  dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  getStateDeposite(): DC_DEPOT_CONST {
    const urlParsed = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    return !_.isUndefined(_.find(urlParsed, ['path', DC_CONST]))
      ? DC_CONST
      : DEPOT_CONST;
  }

  zonePlurial(param: string): string {
    return param
      .split(';')
      .map((v) => v.trim())
      .filter((v) => v !== '').length > 1
      ? 's'
      : '';
  }

  whiteSpaceAddAfterSemicolon(param: string): string {
    return param
      .split(';')
      .map((v) => v.trim())
      .filter((v) => v !== '')
      .join('; ');
  }

  getRole(): ROLE | undefined {
    const role: string | undefined = this.getSessionStorage(
      SessionStorage.USER_SESSION,
      true
    ).profile?.user?.role;
    if (_.isUndefined(role)) {
      return undefined;
    } else if (role === ROLE_PA || /(ROLE_PA)/g.test(role)) {
      return ROLE_PA;
    }
    return role === ROLE_OE || /(ROLE_OE)/g.test(role) ? ROLE_OE : undefined;
  }

  folderSize(fichiers: FichierEnveloppe[]): void {
    fichiers?.forEach((element) => {
      let size = 0;
      element.files?.forEach((e) => {
        if (e.size.includes('ko')) {
          size += Number(e.size.split(' ')[0]);
        } else if (e.size.includes('Mo')) {
          size += Number(e.size.split(' ')[0]) * fsize.Mo;
        } else if (e.size.includes('Go')) {
          size += Number(e.size.split(' ')[0]) * fsize.Go;
        } else if (e.size.includes('To')) {
          size += Number(e.size.split(' ')[0]) * fsize.To;
        }
      });
      switch (true) {
        case size > 33554432:
          element.size = String((size / fsize.To).toPrecision(3)) + ' To';
          break;
        case size > 1048576:
          element.size = String((size / fsize.Go).toPrecision(3)) + ' Go';
          break;
        case size > 1024:
          element.size = String((size / fsize.Mo).toPrecision(3)) + ' Mo';
          break;
        default:
          element.size = String(size) + ' ko';
          break;
      }
    });
  }

  get isDemo(): boolean {
    return (
      this.getSessionStorage(SessionStorage.USER_SESSION, true)?.profile?.user
        ?.demo || false
    );
  }

  redirectionOldMS(module: string, params?: string): void {
    let redirection = environment.msFrLinkRedirection;
    redirection = `${redirection}${this.redirectionHelper.v3ToV2(module as any, params, this.tokenLocalStorage)}`;
    window.open(redirection, '_blank');
    return;
  }
}
