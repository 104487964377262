import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '@atline-shared/components/dialog/login/login.component';
import { ResetPasswordResponse } from '@atline/core/models/api/responses/resertPassword.response';
import { ResetPaswordService } from '@atline/core/services/resetPassword.service';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Input()
  incorrectCredentials!: boolean;

  @Output()
  formData = new EventEmitter();

  public signInForm!: FormGroup;
  public hide = true;
  resetPassword = false;
  resetResponse?: ResetPasswordResponse;
  // public incorrectCredentials = false;

  // TODO add the correct validators for the id/password
  readonly passwordFC = new FormControl('', [Validators.required, Validators.minLength(3)]);
  readonly usernameFC = new FormControl('', [Validators.required, Validators.minLength(3)]);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    public loginDialogRef: MatDialogRef<LoginComponent>,
    private readonly resetPasswordService: ResetPaswordService
  ) { }

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      username: this.usernameFC,
      password: this.passwordFC,
      rememberMe: false,
    });
  }

  /*
  * SHA 256 ENCRYPTION + DATE OF TODAY 
  */
  encryptPassword(password: string): string {
    const currentDate = new Date().toISOString().split('T')[0]; 
    const dataToHash = password + currentDate;
    const encryptedPassword = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex);
    return encryptedPassword;
  }

  /**
   * Get the error message of the password form control validation
   */
  getPasswordErrorMessage(): string {
    if (this.passwordFC.hasError('required')) {
      return 'APP.LOGIN.PASSWORD_ERROR';
    }
    return this.passwordFC.hasError('minlength')
      ? 'APP.LOGIN.PASSWORD_ERROR'
      : '';
  }

  resetPwd() {
    this.resetResponse = undefined;
    if (this.usernameFC.valid)
      this.resetPasswordService.resetPassword(this.usernameFC.value, this.translateService.currentLang).subscribe({
    next: (res) => {
      this.resetResponse = res;
    },
    error: () => {
    }
  })
  }

  /**
 * Submit the form by logging in the user
 * Redirect to dashboard when is success
 */
  onSubmit(): void {
    const encryptedPassword = this.encryptPassword(this.passwordFC.value as string);
    this.signInForm.value.password = encryptedPassword;
    this.formData.emit(this.signInForm)
  }

  onSignupClick(): void {
    this.loginDialogRef.close('signup');
  }

}
