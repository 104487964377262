import { Component, OnInit, Inject, Optional} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { EMAIL_REGEX, PHONE_REGEX } from '@atline/core/models/constant-variable/regex.model';
import { AdminUserCreateRequest } from '@atline/core/models/api/requests/adminUserCreate.request';
import { ConstEnum } from '@atline-shared/enums/const.enum';
import * as _ from 'lodash';
import { Icons } from '@atline-shared/enums/icons.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminUserItem } from '@atline/core/models/adminUser.model';
import { MaskRegExpEnum } from '@atline-shared/enums/maskExp.enum';
import * as forge from 'node-forge';
import { environment } from 'src/environments/environment';
import { UsersListComponent } from '@atline/marches-securises/pa/pa-administrations/users/users-list/users-list.component';
import * as moment from 'moment-timezone';
import { UpdateUserProfileRequest } from '@atline/core/models/api/requests/adminUser.request';




@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {
  public country = new FormControl(ConstEnum.FRANCE_LABEL);
  readonly genders: { value: string, display: string }[] = [
    { value: "mr", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MR' },
    { value: "mme", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MME' },
    { value: "mlle", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.CIVILITY_DISPLAY.MLLE' }
  ];

  readonly fonctions: { value: string, display: string }[] = [
    { value: "", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.DEFAULT' },
    { value: "adm", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.ADMINISTRATION' },
    { value: "com", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.COMMERCIAL' },
    { value: "dir", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.DIRIGEANT' },
    { value: "tec", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.TECHNICIEN' },
    { value: "sm", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FONCTION_DISPLAY.SERVICE_MARCHE' }
  ];

  readonly timeZone: String[] = moment.tz.names();
  readonly timeZoneCurrent = moment.tz.guess();
  

  readonly onglets: { value: string, display: string }[] = [
    { value: "", display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.DEFAULT' },
    { value: 'seal|liste', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.JOUE_BOAMP_JAL' },
    { value: 'fullweb|liste', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_NOUVELLES' },
    { value: 'consultation|en_cours', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_EN_COURS' },
    { value: 'consultation|archivees', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.CONSULTATIONS_ARCHIVEES' },
    { value: 'ressources|ressources', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.RESSOURCES' },
    { value: 'mon_compte|mon_compte', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.DOC_BON_COMMANDE' },
    { value: 'faq', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.FAQ' },
    { value: 'admin|administration', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.ADMINISTRATION' },
    { value: 'entreprise', display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB_DISPLAY.ENTREPRISE' },
  ];

  public addEditUser: FormGroup;
  public hide = true;
  public vone = false;
  public vtwo = false;
  public java = false;

  icons = {
    plus: Icons.Plus,
    eye: Icons.eye,
    group: Icons.group,
    check: Icons.Check,
    save: Icons.Save
  };

  masks = MaskRegExpEnum;

  constructor(
    public dialogRef: MatDialogRef<AddEditUserComponent>,
    private fb: FormBuilder,
    private readonly dialogService: DialogsService,
    private adminUserService: AdminUserService,
    private utils: UtilsService,
    @Optional() public usersList: UsersListComponent,

    @Inject(MAT_DIALOG_DATA) public data: { user: AdminUserItem , ifAdd: boolean, users: AdminUserItem[] | [], usersList: UsersListComponent},

    ) {
        this.usersList = data.usersList;
        this.addEditUser = this.fb.group({ 
          nom: fb.control(data.user?.lastName ?? '', [Validators.required]),
          prenom: fb.control(data.user?.firstName ?? '', [Validators.required]),
          login: fb.control(data.user?.login ?? '', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{4,}$')]),
          nvlogin: fb.control(
            data.user?.nvlogin ?? '', 
            data.user?.nvlogin ? [Validators.required, Validators.pattern('^[a-zA-Z0-9]{4,}$')] : []
          ),
          email: fb.control(data.user?.email ?? '', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
          genre: [data.user?.genre ?? this.genders[0].value, [Validators.required]],
          adresse1: fb.control(data.user?.address?.adresse_1 ?? '', [Validators.nullValidator]),
          adresse2: fb.control(data.user?.address?.adresse_2 ?? '', [Validators.nullValidator]),
          codePostal: fb.control(data.user?.address?.code_postal ?? '', [Validators.nullValidator]),
          commune: fb.control(data.user?.address?.commune ?? '', [Validators.nullValidator]),          
          country: this.country.value,
          tel: fb.control(data.user?.tel ?? '', [Validators.pattern(PHONE_REGEX)]),
          fax: fb.control(data.user?.fax ?? '', [Validators.pattern(PHONE_REGEX)]),
          timezone: fb.control(data.user?.timezone ?? this.timeZoneCurrent, [Validators.nullValidator]),
          password: fb.control(''),
          fonction: fb.control(data.user?.qualification ?? '', [Validators.nullValidator]),
          onglet: fb.control(data.user?.onglet_defaut ?? '', [Validators.required]),
        });
        const passwordControl = this.addEditUser.get('password');

        if (data.ifAdd) {
          passwordControl?.setValidators([
            Validators.required,
            Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{10,}$/)
          ]);
        } else {
          passwordControl?.setValidators([
            (control: AbstractControl) => {
              const value = control.value?.trim();
              return value === '' ? null : /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{10,}$/.test(value)
                ? null
                : { pattern: true };
            }
          ]);
        }
      
        passwordControl?.updateValueAndValidity({ emitEvent: false });
        this.vtwo = Boolean(data.user?.fw2);
        this.java = Boolean(data.user?.dce_java);
      }

  ngOnInit(): void {

  }

  getCountry(event: any): void {
    if (!_.isEmpty(event) && !_.isNil(event))
      this.country.setValue(event);
  }

  // *****************************************************  RSA   *******************************************
  public encryptPassword(password: string): string {
    const publicKeyPem = environment.RsaPublicKey;

    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    if ( password == '' ){
      return '';
    }else{
      const encryptedPassword = publicKey.encrypt(password, 'RSA-OAEP');
      return forge.util.encode64(encryptedPassword);
    }
  }

  onConfirm(): void {
    if (this.addEditUser.value && this.addEditUser.valid) {
      const cle_etab = this.utils.cleEtab || '';
      const cle_ent = this.utils.cleEnt || '';
      const password = this.addEditUser.value.password;
      const encryptedPassword = this.encryptPassword(password);
      const formData = this.addEditUser.value;
      if (!formData.nvlogin) {
        formData.nvlogin = formData.login;
      }
      const {
        nom: lastName,
        prenom: firstName,
        login,
        nvlogin,
        email,
        genre,
        adresse1,
        adresse2,
        codePostal,
        commune,
        tel,
        fax,
        timezone,
      } = this.addEditUser.value;

      let v2:1|0 = 0;
      let java:1|0 = 0;


      if (this.vtwo == true) {
        v2 = 1;
      }

      if (this.java == true) {
        java = 1;
      }


      const adminUserCreateRequest: AdminUserCreateRequest = {
        login,
        nvlogin,
        password: encryptedPassword,
        genre: genre,
        lastName: lastName,
        firstName,
        adresse_1: adresse1,
        adresse_2: adresse2,
        code_postal: parseInt(codePostal),
        commune: commune,
        // pays: this.addEditUser?.get('country')?.value,

        email,
        tel,
        fax,
        timezone,
        cle_etab: cle_etab,
        cle_ent: cle_ent,
        qualification: this.addEditUser?.get('fonction')?.value,
        autorized_apps: JSON.stringify({"demo_fw":0, "bo":0 , "ms2":1 , "asec":0 , "bb":0 , "ts":0 , "es":0, "helios":0, "acte":0, "parapheur":0, "convocation":0, "espace":0, "attestations":0}),
        paramsMS: JSON.stringify({
          defaultTab: this.addEditUser?.get('onglet')?.value,
          fw2: v2,
          java: java
        }),
        isAdd: this.data.ifAdd,
      };

      this.adminUserService
        .addUser(adminUserCreateRequest)
        .subscribe(
      () => {
        this.dialogRef.close();
        if(this.data.ifAdd){
          this.usersList.added = this.data.ifAdd;
        }else{
          this.usersList.edited = true;
          const req = new UpdateUserProfileRequest();
          req.cle_etab = cle_etab;
          req.cle_utilisateur = this.data.user.idIdent;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        }
        this.usersList.callToWs();
      },
      () => {
        if(this.data.ifAdd){
          this.usersList.added = this.data.ifAdd;
        }else{
          this.usersList.edited = true;
          const req = new UpdateUserProfileRequest();
          req.cle_etab = cle_etab;
          req.cle_utilisateur = this.data.user.idIdent;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        }
          this.usersList.callToWs();
      }
  );

      this.dialogService.closeCreationGroupDialog(true);
      return;
    }
  }

  onDismiss(): void {
    this.dialogService.closeCreationGroupDialog(false);
  }

  close(): void {
    this.dialogRef.close();
  }

  openUserRights(): void {
    this.usersList.user = this.data.user;
    this.dialogRef.close();
    this.usersList.rights=true;
    this.usersList.callToWs();
    // ********************* find alternative **********
    // window.scrollTo({
    //   behavior: 'smooth',
    //   top: 0
    // })

  }

}
