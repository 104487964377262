import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddOrDeleteFavorisRequest, FavorisRequest } from '../models/api/requests/favoris.request';
import { FavorisResponse } from '../models/api/responses/favoris.response';
import { DefaultResponse } from '../models/api/responses/default.response';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';
import { ConsultationOeItem } from '../models/consultation/ConsultationOeItem.model';

@Injectable({
  providedIn: 'root'
})
export class FavorisService {

  private numberOfFavorisBS = new BehaviorSubject<number>(0);
  numberOfFavoris = this.numberOfFavorisBS.asObservable();

  constructor(private readonly apiService: ApiService) { }

  /**
   * Get list of favoris
   * @returns array of all consulations
   */
   favorisList(params: FavorisRequest): Observable<FavorisResponse> {
     return this.apiService.post(FavorisResponse, params, 'favorite_consultation_list').pipe(
      tap((res) => res.consultations_favorites = res.consultations_favorites.map((c: any) => new ConsultationOeItem(c))
    ));
  }

  /**
   * Add consultation to favorite list
   * @returns array of all consulations
   */
   addConsultationFavoris(params: AddOrDeleteFavorisRequest): Observable<DefaultResponse> {
     return this.apiService.post(DefaultResponse, params, 'favorite_consultation_add');
  }

  /**
   * Delete consultation to favorite list
   * @returns array of all consulations
   */
   deleteConsultationFavoris(params: AddOrDeleteFavorisRequest): Observable<DefaultResponse> {
     return this.apiService.post(DefaultResponse, params, 'favorite_consultation_delete');
  }

  /**
   * get the save number of favoris as an observable<number>
   * @returns observable with number of favoris
   */
  getNumberOfFavoris(): Observable<number> {
    return this.numberOfFavoris;
  }

  /**
   * save number of favoris
   * @param b boolean
   */
  setNumberOfFavoris(n: number): void {
    this.numberOfFavorisBS.next(n);
  }

}
