import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MethodRequest } from '@atline-shared/enums/method-request.enum';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddAwardNotificationRequest, DeleteAwardNotificationsRequest, GetAwardNotificationsRequest } from '../models/api/requests/awardNotification.requst';
import { AwardNotificationResponse } from '../models/api/responses/awradNotification.response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AwardNotificationService {

  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient
  ) { }

  getAttributions(req: GetAwardNotificationsRequest): Observable<AwardNotificationResponse> {
    return this.apiService.post(AwardNotificationResponse, req, 'dce_avis_attributions');
  }

  deleteAwardNotification(req: DeleteAwardNotificationsRequest): Observable<AwardNotificationResponse> {
    return this.apiService.post(AwardNotificationResponse, req, 'avis_delete');
  }

  postAwardNotification(req: AddAwardNotificationRequest): Observable<any> {
    const formData = new FormData();
    formData.append('cle_etab', req.cle_etab);
    formData.append('cle_pa', req.cle_pa);
    formData.append('cle_dce', req.cle_dce);
    formData.append('type_avis', req.type_avis.toString());
    formData.append('date_debut', req.date_debut);
    formData.append('date_fin', req.date_fin);
    formData.append('description', req.description);
    formData.append('avis', req.avis, req.avis.name);
    return this.apiService.post(AwardNotificationResponse, formData, 'avis_add');
  }

  getFile(req: { cle_avis: string }): Observable<HttpResponse<any>> {
    return this.http.post(
      `${environment.api}avis_get`, req,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
