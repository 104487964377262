<div class="container">
    <div class="content" [class.default]="appearance === 'default'" [class.square]="appearance === 'square'" fxLayout="column" fxLayoutAlign="center center" *ngIf="nbConsultation != 0 && pagination">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="arrow" [ngClass]="{'disabled': isOnFirstPage}" (click)="goToFollowingPage('back')" [title]="'APP.PAGINATION.LEFT-ARROW' | translate">
            <mat-icon [svgIcon]="isListAffairesUrl || isListGroupsUrl ? Icons.chevron_grey : Icons.Arrow"
                      [ngClass]="{'chevron-left': isListAffairesUrl || isListGroupsUrl, 'arrow-left': !isListAffairesUrl && !isListGroupsUrl}">
            </mat-icon>
        </div>

              <div class="numbers" fxLayout="row">

                  <div class="number" *ngIf="displayFirstPageNumber  && !isListAffairesUrl" (click)="gotToPage(1)">
                      1&nbsp;. . .&nbsp;
                  </div>

                  <div class="number" *ngFor="let item of numberNgFor; let i = index" fxLayout="row" 
                        [class.container-focused]="pagination.page == item" (click)="gotToPage(item)">
                      <div [ngClass]="{'focused': pagination.page == item, 'orange': pagination.page == item}">
                          {{item}}
                      </div>
                      <div class="separator number" *ngIf="i < (numberNgFor.length - 1) && nbConsultation != 0  && !isListAffairesUrl">

                      </div>

                  </div>
                  <div class="number" *ngIf="displayLastPageNumber  && !isListAffairesUrl" (click)="gotToPage(nbPage)">
                      &nbsp;. . .&nbsp;{{nbPage}}
                  </div>
              </div>

              <div class="arrow" [ngClass]="{'disabled': isOnLastPage}" (click)="goToFollowingPage('foward')" [title]="'APP.PAGINATION.RIGHT-ARROW' | translate">
                <mat-icon [svgIcon]="isListAffairesUrl || isListGroupsUrl ? Icons.chevron_grey : Icons.Arrow"
                          [ngClass]="{'chevron-right': isListAffairesUrl || isListGroupsUrl}">
                </mat-icon>
            </div>
          </div>


          <div class="nbParPage" fxLayout="row" *ngIf="!hideNbParPage">
              <div *ngFor="let item of nbParPageList; let i = index" class="interval" fxLayout="row"
                  fxLayoutAlign="center center">
                  <div (click)="updateNbParPage(item)" class="pointeur"
                      [ngClass]="{'focused-nbr-page': pagination.nbr_par_page.toString() == item.toString() }"  
                      [title]="item + ' par page'">
                      {{item}}
                  </div>
                  <div class="separator-interval" *ngIf="i != nbParPageList.length - 1  && nbConsultation != 0  && !isListAffairesUrl">
                      -
                  </div>
              </div>
          </div>

      </div>
  </div>
