<app-dialog [dialogRef]="dialogRef">

    <ng-container header>
        {{'APP.DIALOG.PA_SELECT.TITLE' | translate}}
    </ng-container>

    <ng-container body>

        <app-field-edition [label]="'APP.DIALOG.PA_SELECT.LABEL' | translate">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="clePa">
                    <mat-option *ngFor="let pa of pas" [value]="pa.cle_pa">{{pa.denomination_pa}}</mat-option>
                </mat-select>
            </mat-form-field>

        </app-field-edition>
    </ng-container>

    <ng-container footer>
        <div fxLayout="row" fxLayoutAlign="end" fxFlex="100">
            <app-button [icon]="iconCheck" [disabled]="!clePa" (click)="dialogRef.close(clePa)" size="medium"></app-button>
        </div>
    </ng-container>

</app-dialog>