<app-dialog [dialogRef]="dialogRef">
  <span *ngIf="data?.user" header>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.TITLE_USER' | translate : { firstname: user?.firstName} | uppercase }} </span>
  <span *ngIf="data?.group" header>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.TITLE_GROUP' | translate : { nom: group?.label} | uppercase}} </span>

  <div body class="add-user-section">
    <div *ngIf="data?.user" class="TitleLabel">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.SELECT_USER' | translate }}
    </div>     
    <div *ngIf="data?.group" class="TitleLabel">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.SELECT_GROUP' | translate }}
    </div> 
    <div body class="add-user">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="data?.user">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.SELECT_USER_LIST' | translate }}</mat-label>
        <mat-label *ngIf="data?.group">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.SELECT_GROUP_LIST' | translate }}</mat-label>
        <mat-select *ngIf="data?.user" [(ngModel)]="selectedGroup">
          <mat-option *ngFor="let group of availableGroups" [value]="group">
            {{group.nom}} 
          </mat-option>
        </mat-select>
        <mat-select *ngIf="data?.group" [(ngModel)]="selectedUser">
          <mat-option *ngFor="let user of availableUsers" [value]="user">
            {{user.firstName}} {{user.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
    
      <button *ngIf="data?.user" mat-raised-button color="primary" 
              [disabled]="!selectedGroup"
              (click)="addUserGroupLink()"
              class="add-button">
        <mat-icon>add</mat-icon>
      </button>
      <button *ngIf="data?.group" mat-raised-button color="primary" 
              [disabled]="!selectedUser"
              (click)="addUserGroupLink()"
              class="add-button">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div *ngIf="data?.group" class="TitleLabel">
    {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.USERS_LIST' | translate }}
    </div>
    <div *ngIf="data?.user" class="TitleLabel">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.GROUPS_LIST' | translate }}
      </div>
  </div>
  

  <table *ngIf="users.length && data?.group" class="users-table" body mat-table [dataSource]="users">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.HEADER_NAME' | translate | uppercase }}</th>
      <td mat-cell *matCellDef="let user" >{{ user.firstName | uppercase }} </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.HEADER_PRENOM' | translate | uppercase }}</th>
      <td mat-cell *matCellDef="let user">{{ user.lastName | uppercase }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button 
                [disabled]="false"
                (click)="deleteUserGroupLink(user, null)">
          <mat-icon svgIcon="bin"></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <div *ngIf="data?.group">
      <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
    </div>
    <!-- <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr> -->
  </table>

  <table *ngIf="groups.length && data?.user" class="users-table" body mat-table [dataSource]="groups">
    <ng-container matColumnDef="nom">
      <th mat-header-cell *matHeaderCellDef>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.HEADER_NAME' | translate | uppercase}}</th>
      <td mat-cell *matCellDef="let group">{{ group.nom | uppercase }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let group">
        <button mat-icon-button 
                [disabled]="false"
                (click)="deleteUserGroupLink(null, group)">
          <mat-icon svgIcon="bin"></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
    <!-- <div *ngIf="data?.group">
      <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
    </div> -->
    <div *ngIf="data?.user">
      <tr mat-row *matRowDef="let group; columns: displayedColumnsUser;"></tr>
    </div>
  </table>
    <div body >
      <div *ngIf="users.length === 0 && data?.group" class="empty-list-message">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.EMPTY_USER' | translate }}</div>
      <div *ngIf="groups.length == 0 && data?.user" class="empty-list-message">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USERS.EMPTY_GROUP' | translate }}</div>
    </div>

</app-dialog>
