import { ApiRequest } from './api.request';
import { PaginationRequest } from './pagination.request';

export class AdminUserRequest extends ApiRequest{

    // tslint:disable:variable-name
    cle_etab !:string
    cle_ent!:string
    recherche?: string;
    pagination?: PaginationRequest;
    // tslint:enable:variable-name

}

export class AdminUserRightsRequest extends ApiRequest{

    // tslint:disable:variable-name
    cle_etab !:string
    cle_pa!:string
    cle_utilisateur?: string;

    // tslint:enable:variable-name

}

export class AdminLinkUserGroupRequest extends ApiRequest{
    cle_etab !: string
    cle_pa !: string 
    cle_groupe!: string
    id_ident !: string;
}

export class UpdateUserProfileRequest extends ApiRequest{
    cle_etab !: string
    cle_utilisateur !: string 
    //set or reset
    contexte!: string
}