export enum Icons {
  Grid = 'grid',
  Talk = 'talk',
  Headset = 'headset',
  Arrow = 'right-arrow',
  Arrow2 = 'arrow',
  Tender = 'tender',
  Training = 'training',
  Secure = 'secure',
  Sign = 'sign',
  Helios = 'helios',
  Mail = 'mail',
  Space = 'space',
  Acte = 'acte',
  User = 'user',
  Valid = 'valid',
  ValidBlue = 'valid_blue',
  ValidGreen = 'valid_green',
  Hotline = 'hotline-headset',
  Meeting = 'meeting',
  AtsLogo = 'logo-ats',
  OrLine = 'or-line',
  JavaKey = 'java-key',
  Logout = 'logout',
  Etoile = 'star',
  Attestations = 'attestations',
  Convocations = 'convocations',
  AtlineFrontActesHelios = 'ATLINE-FRONT-ACTES-HELIOS',
  AtlineFrontAtlinesFormation = 'ATLINE-FRONT-ATLINE-FORMATION',
  AtlineFrontAttestations = 'ATLINE-FRONT-ATTESTATIONS',
  AtlineFrontConvocations = 'ATLINE-FRONT-CONVOCATIONS',
  AtlineFrontEspaces = 'ATLINE-FRONT-ESPACES',
  AtlineFrontMarches = 'ATLINE-FRONT-MARCHES',
  AtlineFrontParapheur = 'ATLINE-FRONT-PARAPHEUR',
  Page404 = 'PAGE-404',
  PageEnCoursDeConstruction = 'PAGE-EN-COURS-DE-CONSTRUCTION',
  TenderWhite = 'tender-white',
  TrainingWhite = 'training-white',
  SecureWhite = 'secure-white',
  SignWhite = 'sign-white',
  FrontStaticEcran = 'FRONT-STATIC-ECRAN',
  CadenasOpen = 'cadenas-open',
  CadenasClose = 'cadenas-close',
  Book = 'book',
  BookWhite = 'book_white',
  BookGrey = 'book_grey',
  // Attributaire = 'attributaires',
  AttributaireSelect = 'attributaire-select',
  EditPencil = 'editpencil',
  BlueArrow = 'blue_right_arrow',
  // Info = 'info',
  Clock = 'clock',
  Plus = 'plus',
  PlusWhite = 'plus_white',
  Check = 'check',
  Save = 'save',
  PDF = 'pdf',
  CSV = 'csv',
  PDFGrey = 'pdf_grey',
  PDFRed = 'pdf_red',
  DOC = 'doc',
  XLS = 'xls',
  ZIP = 'zip',
  File = 'file',
  Shield = 'shield',
  loupe = 'loupe',
  target = 'target',
  XML = 'xml',
  QuestionBubble = 'questionBubble',
  EvelopeButton = 'envelope_button',
  EvelopClosed = 'envelope_closed',
  Aapc = 'aapc',
  Dce = 'dce',
  Pencil = 'editpencil',
  Bin = 'bin',
  DEPOSITES = 'depots',
  RETRAITS = 'retraits',
  Attributaires = 'attributaires',
  OpenedLock = 'cadenas_open',
  ClosedLock = 'cadenas_close',
  Info = 'info',
  InfoGreen = 'info_green',
  Duplicate = 'duplicate',
  Send = 'send',
  ZipMarche = 'zip_marche',
  Disconnect = 'disconnect',
  Loup = 'loupe',
  AddMessage = 'add_message',
  LeftArrow = 'arrow_left',
  Close = 'close',
  CloseWhite = 'close_white',
  Valid_green = 'valid_green',
  Red_Cross = 'cross_red',

  Download = 'download',
  LotFerme = 'lot_ferme',
  Lotouvert = 'lot_ouvert',
  AnyFile = 'any_file',

  EnveloppeFermeWhite = 'envelope_closed_white',
  TraceHautDroitForm = 'tracé',

  // new
  cursor = 'cursor',
  keyTender = 'key_tender',
  key = 'key',
  group = 'group',
  keys = 'keys',
  userConfig = 'user_config',
  services = 'services',
  wrench = 'wrench',
  envelop = 'envelop',
  registrationOffice = 'registration_office',
  chevron = 'chevron',
  chevron_grey = 'chevron_grey',
  chevron_bleu = 'chevron_bleu',
  eye = 'eye',
  annuaire = 'annuaire',
  annuairee = 'annuairee',
  TargetBlue = 'target_blue',
  Pin = 'pin',
  Calendar = 'calendar',

  StatistiquesHistorique = 'StatistiquesHistorique',
  Annuaires = 'Annuaires',
  guichetEnregistrement = 'guichetEnregistrement',
  ListeAffaires = 'ListeAffaires',
  ExternalisationDce = 'ExternalisationDce',
  StyloElectroniqueWebSignPdf = 'StyloElectroniqueWebSignPdf',
  // value = "value",
  // groups = "groups"
  // flags
  FlagUE = 'flag-ue',
  FlagMarianneFR = 'flag-fr-marianne',
  FilledArrow = 'filledArrow',
  Copy='copy',
  Multiuser="multiuser",
  Clear = 'clear',
  Chevron = 'chevron',
  Refresh = 'refresh',
  Microsoft = 'microsoft',
  Share = 'share',
  Reply = 'reply',
  Reply_all = 'reply_all',
  DEPOSITE_BID_REPORT = 'deposit_report',
  NotVisible = 'not_visible',
  EasyOpen='easyopen'
}
