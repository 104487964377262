export class GroupItem {
  // tslint:disable:variable-name
  id!: string;
  cle_gr!: string;
  nom!: string;
  cle_etablissement!: string;
  id_element!: string;

  // tslint:enable:variable-name
}
