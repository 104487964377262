import { AdminUserRequest } from './../../../../../core/models/api/requests/adminUser.request';
import { Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import { AdminUserResponse } from '@atline/core/models/api/responses/adminUser.response';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { AdminUserItem } from '@atline/core/models/adminUser.model';
import { PaService } from '@atline/core/services/pa.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { AdminUserDeleteRequest } from '@atline/core/models/api/requests/adminUserCreate.request';
import { Icons } from '@atline-shared/enums/icons.enum';
import { GroupItem } from '@atline/core/models/groupItem.model';
import { ConfigEtabService } from '@atline/core/services/configEtab.service';



@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() cross = true;
  @Input() legendType!: 'user' | 'group';
  userListAdmin!:AdminUserResponse;
  nbUsers: number = 0;
  isLoading: boolean = true;
  cleEtab!: string;
  filteredUsers: AdminUserItem[] = [];
  searchUserTerm = '';
  public show: boolean = false;
  public added: boolean = false;
  public edited: boolean = false;
  public legendVisible = false;
  public paVisible = false;
  public user!: AdminUserItem;
  groups: GroupItem[] = [];
  public paList: any;
  public rights: boolean = false;
  readonly Icons = Icons;
  sortedColumn: string = '';
  sortAscending: boolean = true;
  public configList: any;
  public hasGroups: boolean = false;
  public hasPas: boolean = false;



  constructor(
    private readonly adminUserService: AdminUserService,
    private readonly utils: UtilsService,
    private readonly paService: PaService,
    private readonly dialogService: DialogsService,
    private readonly configEtab: ConfigEtabService,


  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.legendType = 'user';
    this.cleEtab = this.utils.cleEtab || '';

    this.callToWs();
    this.paService.getPAs({
      cle_etab: this.cleEtab
    }).subscribe(
      {
        next: (res) => {
          this.paList= res.pas;
   
        },
      });
      

    this.configEtab.getConfigEtab({
      cle_etab: this.cleEtab
    }).subscribe(
      {
        next: (res) => {
          this.configList= res.configs;
          if(this.configList[0].code == "v_gr" && this.configList[0].etat == true){
            this.hasGroups = true;
          }
          if(this.configList[3].code == "v_m_pa" && this.configList[3].etat == true){
            this.hasPas = true;
          }
        },
      }); 


  }

  public callToWs(): void {

    const request: AdminUserRequest = {
      cle_etab: this.cleEtab,
      cle_ent: this.utils.cleEnt ?? '',
    };

    this.adminUserService.adminUser(request).subscribe(
      (res) => {
        this.sortedColumn = 'lastName'; 
        this.sortAscending = false; 
        this.userListAdmin = res;
        this.nbUsers = this.userListAdmin?.nb_total || 0;
        this.userListAdmin.utilisateurs = [...res.utilisateurs];
        this.filterUsers();
        this.sortUsers(this.sortedColumn as keyof AdminUserItem);
        this.isLoading = false;
      },
      (err) => {
        const apiError = err.error as ApiErrorResponse;
        if (apiError) {
        }
        this.isLoading = false;
      }
    );
  }

  getPlurial(n: number): string {
    return n > 1 ? 's' : '';
  }

  get users(): AdminUserItem[] {
    return this.userListAdmin?.utilisateurs || [];
  }

  filterUsers(): void {
    this.filteredUsers = this.userListAdmin.utilisateurs.filter((user) =>
      user.firstName.toLowerCase().includes(this.searchUserTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(this.searchUserTerm.toLowerCase()) ||
      user.login.toLowerCase().includes(this.searchUserTerm.toLowerCase())
    );
  }

  searchUsers(searchTerm: string): void {
    this.searchUserTerm = searchTerm;
    this.filterUsers();
  }

  addUser(): void {
    this.show = true;
  }
  
  openFormUserDialog(user: AdminUserItem | null, ifAdd: boolean,users:AdminUserItem[] | [], usersList: UsersListComponent) {
    this.dialogService.openAddEditUser(user, ifAdd, users, usersList);
  }


  close(): void {
    this.added =false;
    this.edited = false;
  }

  closeGrp(): void {
    this.rights = false;
    this.callToWs;
  }


  legendVisibility(): void {
    this.legendVisible = !this.legendVisible;
  }

  paPart(currentUser : AdminUserItem): void {
    this.user = currentUser;
    this.paVisible = true;
  }

  openAssingUserPaDialog(user: AdminUserItem, userList: UsersListComponent): void {
    this.dialogService.openUserPa(user, userList);
  }
  
  countPa(currentUser : AdminUserItem): number {
    if (currentUser.paList) {
      return currentUser.paList.length;
    }
    return 0;
  }

  openUserRights(currentUser : AdminUserItem): void {
    this.user = currentUser;
    this.rights=true;
  }
  public openSoonDialog(): void {
    this.dialogService.createInfoDialog('APP.SOON.POP_UP', '', 'OK');
  }

  openUserGroups(user: AdminUserItem, userList: UsersListComponent): void {
    this.dialogService.openUserGroupLink({
      groups: user.groups as GroupItem[] ,
      user: {
        cle_personne: user.cle_personne,
        id_ident: user.idIdent,
        login: user.login,
        firstName: user.firstName,
        lastName: user.lastName 

      },userList
    });
  }

  updateUserList(user: AdminUserItem): void {
    this.userListAdmin.utilisateurs = this.userListAdmin.utilisateurs.filter(
      (item) => item.login !== user.login
    );
    this.filterUsers();
  }

  deleteUser(user: AdminUserItem): void {
    const cleEnt = this.utils.cleEnt || '';

    const UserLogin = user.login;
    const clePersone = user.cle_personne;

    const adminUserDeleteRequest: AdminUserDeleteRequest = {
      cle_personne: clePersone,
      cle_ent: cleEnt
    };

    if (clePersone && clePersone.trim() !== '') {
      const dialogRef = this.dialogService.openConfirmationDialog({
        title: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TITLE_REMOVE',
        description: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.REMOVE_CONFIRMATION',
        data: { UserLogin }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.adminUserService.deleteUser(adminUserDeleteRequest).subscribe(
            () => {
              this.nbUsers--;
              this.updateUserList(user);
            },
            (err) => {
              const apiError = err.error as ApiErrorResponse;
              if (apiError) {

              }
            }
          );
        }
      });
    }
  }
  sortUsers(column: keyof AdminUserItem): void {
    if (this.sortedColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortedColumn = column;
      this.sortAscending = true;
    }
  
    this.filteredUsers = [...this.filteredUsers].sort((a, b) => {
      const valueA = a[column]?.toString().toLowerCase() ?? '';
      const valueB = b[column]?.toString().toLowerCase() ?? '';
      return this.sortAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    });
  }
}
