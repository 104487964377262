<div class="container">

  <div class="content" fxLayout="column" fxLayoutAlign="space-around center">
    <h1 class="title" fxFlex="100">
      {{'APP.MARCHES_SECURISES.CONSULTATION.LOGIN.TITLE' | translate}}
    </h1>

    <div fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between center" fxFlex="100">

      <div class="anonyme" fxFlex.gt-md="50" fxFlex.lt-lg="100">

        <ng-container *ngIf="isLoading">
          <mat-spinner [style.margin]="'auto'" [diameter]="50"></mat-spinner>
        </ng-container>


        <ng-container *ngIf="cleEtab; then anonymeLink; else notAvailable"></ng-container>
        <ng-template #anonymeLink>
          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
            <h3 class="title">
              {{'APP.MARCHES_SECURISES.CONSULTATION.ANONYMOUS_DETAILS_1' | translate}}
            </h3>
            <a atlineLink (click)="downloadDCE()" class="link">
              {{'APP.MARCHES_SECURISES.CONSULTATION.ANONYMOUS_DETAILS_2' | translate}}
            </a>
            <div>
              <p [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.ANONYMOUS_DETAILS_3' | translate"></p>
            </div>
          </div>

        </ng-template>
        <ng-template #notAvailable>
          {{'APP.MARCHES_SECURISES.CONSULTATION.ANONYMOUS_AVAILABLE' | translate}}
        </ng-template>
      </div>

      <mat-divider fxHide.lt-lg="true" vertical></mat-divider>
      <mat-divider fxHide.gt-md="true"></mat-divider>

      <div class="connexion" fxFlex.gt-md="50" fxFlex.lt-lg="100" fxLayout="column" fxLayoutAlign="center center">

        <app-login-form class="full-width" (formData)="onSubmit($event)"></app-login-form>


        <!-- <form [formGroup]="signInForm" class="mat-bg-card" (ngSubmit)="onSubmit()" fxLayout="column"
          fxLayoutAlign="center center">

          <div fxLayout="column" class="signup-form-container-field">
            <mat-form-field class="full-width form-field" appearance="outline">
              <mat-icon matPrefix>person</mat-icon>
              <input matInput formControlName="username" name="username"
                placeholder="{{'APP.MARCHES_SECURISES.CONSULTATION.LOGIN.PLACEHOLDER.IDENTIFIANT' | translate}}" />
            </mat-form-field>
            <small *ngIf="usernameFC.invalid && usernameFC.touched" class="form-error-msg"> {{
              'APP.LOGIN.USERNAME_ERROR' | translate}}</small>
          </div>
          <div>
            <mat-form-field class="full-width form-field" appearance="outline">
              <mat-icon matPrefix>lock</mat-icon>
              <input matInput type="password" formControlName="password" name="password"
                placeholder="{{'APP.MARCHES_SECURISES.CONSULTATION.LOGIN.PLACEHOLDER.PASSWORD' | translate}}" />
            </mat-form-field>
            <small *ngIf="passwordFC.invalid && passwordFC.touched" class="form-error-msg">
              {{getPasswordErrorMessage() | translate}} </small>
          </div>
          <button type="submit" mat-stroked-button
            class="full-width lg-border-radius connexion-btn">{{'APP.LOGIN.CONNECT' | translate}}</button>
          <button type="button" mat-stroked-button (click)="onSignupClick()"
            class="stroked-bn full-width lg-border-radius createAccount">{{'APP.MARCHES_SECURISES.CONSULTATION.LOGIN.CREATE'
            | translate}}
          </button>
        </form> -->
      </div>

    </div>

  </div>
</div>
