<div class="container" fxLayout="row" fxLayoutAlign="center center" fxHide.lt-md>
  <div fxLayout="row">
    <img src="../../../assets/icons/svg/tender.svg" alt="" class="ms_logo" />
    <div class="title_ms">
      {{ "APP.MARCHES_SECURISES.TITLE_MARCHES" | translate | uppercase }} <br />
      {{ "APP.MARCHES_SECURISES.TITLE_SECURISES" | translate | uppercase }}
    </div>
  </div>

    <div class="navLink" fxLayoutAlign="space-around center">
        <mat-nav-list fxLayout="row">
            <a atlineLink [routerLink]="['./']"   [class.active]="currentRoute === ''" class="link min-100">{{'APP.MARCHES_SECURISES.NAV_LINK.SEARCH' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['donnees-essentielles']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.MAIN_DATA' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['avis-attribution']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.AWARD' | translate | uppercase}}</a>
            <a atlineLink *ngIf="!!isLogged" [routerLink]="['historique']" routerLinkActive="active" class="link">{{"APP.MARCHES_SECURISES.NAV_LINK.HISTORY" | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['certificat-signature']" routerLinkActive="active" class="link min-100">{{'APP.MARCHES_SECURISES.NAV_LINK.CERTIFICATS' | translate | uppercase}}</a>
            <a atlineLink *ngIf="!isLogged" target="_blank" href="https://www.marches-securises.fr/entreprise/?module=inscription" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.REGISTRATION' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['faq']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.FAQ' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['liens-utiles']" routerLinkActive="active" class="link disabled-link">{{'APP.MARCHES_SECURISES.NAV_LINK.USEFUL_LINKS' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['qui-sommes-nous']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.WHO_ARE_WE' | translate | uppercase}}</a>
            <a atlineLink [routerLink]="['patenaires-references']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.PARTNERS' | translate | uppercase}}</a>
            <a atlineLink *ngIf="(isPa | async) && activePa" [routerLink]="['pa']" routerLinkActive="active" class="link">{{'APP.MARCHES_SECURISES.NAV_LINK.SPACE-PA' | translate | uppercase}}</a>
        </mat-nav-list>
    </div>
</div>
