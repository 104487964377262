import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../dialog/login/login.component';
import { AuthenticateService } from '../../../core/services/authenticate.service';
import { Router } from '@angular/router';
import { CreateAccountComponent } from '../../../home/create-account/create-account.component';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { RoutingService } from '@atline/core/services/routing.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  isOnAService = false;

  public hotlineNumber: string = environment.hotline;
  public servicesMenuIsOpen = false;
  public isLoggedIn = false;
  public atlineFormation = environment.atlineFormation;
  public servicesMenuHamburgerIsOpen = false;
  public oldVersion = false;

  constructor(
    public dialog: MatDialog,
    private readonly authenticator: AuthenticateService,
    private readonly router: Router,
    private readonly globalDataService: GlobalDataService,
    private readonly utilsService: UtilsService,
    private readonly routingService: RoutingService,
    readonly mediaMatcher: MediaMatcher,
  ) { }

  toggleMobileMenu(): void {
    this.servicesMenuHamburgerIsOpen = !this.servicesMenuHamburgerIsOpen;
  }

  ngOnInit(): void {
    this.globalDataService.isLogged.subscribe(res => {
      this.isLoggedIn = res;
    });
  }

  get isConnected(): boolean {
    return this.utilsService.isLoggedWithAuthorizationCode;
  }

  get hasGestionnaire() {
    return this.utilsService.gestionnaire;
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent
      , {
        panelClass: 'login-dialog',
        maxWidth: '95vw',
        position: {
          top: '64px'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'signup') {
        this.openSignUpDialog();
      }
    });
  }
  openSignUpDialog(): void {
    this.dialog.open(CreateAccountComponent
      , {
        panelClass: 'signup-dialog',
        maxWidth: '95vw',
        position: {
          top: '64px'
        },
      },
    );
  }
  logout(): void {
    this.globalDataService.getIsLogged().subscribe(res => {
      this.isLoggedIn = res;
    });
    this.authenticator.logout();
    this.router.navigate([RoutingEnum.HOME]);
  }


  /**
   * TODO
   */
  openCloseServicesMenu(): void {
    this.servicesMenuIsOpen = !this.servicesMenuIsOpen;
  }

  goOldVersion(gestionnaire = false): void {

    if (!this.utilsService.isLoggedWithAuthorizationCode) {
      window.location.href = environment.msLink;
      return
    }

    if(gestionnaire) {
      this.utilsService.redirectionOldMS('gestion');
      return;
    }
    const url = this.router.url.split('/');
    url.shift();

    if (url[0] === this.routingService.firstFragment) {
      url.shift();
    }

    if (url[0] === RoutingEnum.PA) {
      this.utilsService.redirectionOldMS(url[1], url[2]);
    } else {
      this.utilsService.redirectionOldMS(url[0]);
    }
  }
 

}
