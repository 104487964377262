
<app-dialog [dialogRef]="dialogRef">
    <ng-container header>
        <h1 *ngIf= "data.ifAdd">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ADD_USER_LABEL' | translate | uppercase}}</h1>
        <h1 *ngIf= "!data.ifAdd">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.UPDATE_USER_LABEL' | translate | uppercase}}</h1>
    </ng-container>

    <div class="reduced-margins" body>

      <div class="body">
        <div fxLayout="row" fxLayoutGap="75px">
          <div class="input" fxLayout="column" fxFlex="50">
            <div fxLayout="column">
              <h2>{{
                'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.COORDONNÉES'
                | translate
                | uppercase
                }}
              </h2>
  
              <div class="mb"> </div>
            </div>
          </div>
  
          <div class="input" fxLayout="column" fxFlex="50">
            <div fxLayout="column">
              <h2>{{
                'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.IDENTIFIANTS'
                | translate
                | uppercase
                }}
              </h2>
  
              <div class="mb"> </div>
            </div>
          </div>
        </div>
        <form body [formGroup]="addEditUser" fxLayout="column" style="padding-left: 15px;">   
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_GENDER'
                  | translate
                  }}
  
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="35">
                  <mat-select formControlName="genre">
                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                      {{ gender.display | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_IDENTIFIANT'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="login" />
                </mat-form-field>
              </div>
              <h5 class="p-second">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.WARNING_IDENTIFIANT' | translate }}</h5>
            </div>

            <div *ngIf= "!data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_IDENTIFIANT_ACTUEL'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="login" readonly/>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_FIRST_NAME'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="prenom" />
                  <mat-error class="matError"
                    *ngIf="addEditUser.controls['prenom'].invalid && (addEditUser.controls['prenom'].touched)">
                    {{"APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ERROR_PRENOM" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "data.ifAdd" class="input third" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PWD'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password" />
                </mat-form-field>
              </div>
              <h5>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.WARNING_PWD' | translate }}</h5>
            </div>
            <div *ngIf= "!data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_IDENTIFIANT_NOUVEAU'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="nvlogin" />
                </mat-form-field>
              </div>
              <h5 class="p-second">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.WARNING_IDENTIFIANT' | translate }}</h5>
            </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_LAST_NAME'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="54">
                  <input matInput type="text" formControlName="nom" />
                  <mat-error class="matError" *ngIf="addEditUser.controls['nom'].invalid && (addEditUser.controls['nom'].touched)">
                    {{"APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ERROR_NOM" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf= "!data.ifAdd" class="input third" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PWD'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password" />
                </mat-form-field>
              </div>
              <h5>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.WARNING_PWD' | translate }}</h5>
            </div>
          </div>
          
          <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_MAIL'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="54">
                  <input matInput type="text" formControlName="email" />
                  <mat-error class="matError"
                    *ngIf="addEditUser.controls['email'].invalid && (addEditUser.controls['email'].touched)">
                    {{"APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ERROR_MAIL" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_FONCTION'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="33">
                  <mat-select formControlName="fonction">
                    <mat-option *ngFor="let fonction of fonctions" [value]="fonction.value">
                      {{ fonction.display | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_ADRESS_1'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="54">
                  <input matInput type="text" formControlName="adresse1" />
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_ADRESS_2'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="54">
                  <input matInput type="text" formControlName="adresse2" />
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_CP'
                  | translate
                  }}</span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="54">
                  <input matInput type="text" formControlName="codePostal" />
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_COMMUNE'
                  | translate
                  }}</span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="commune" />
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "!data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="column">
                <h2>{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.GESTION_DROITS'
                  | translate
                  | uppercase
                  }}
                </h2>
  
                <div class="mb3"> </div>
              </div>
            </div>
            <div *ngIf= "data.ifAdd" fxLayout="column" fxFlex="50">
              <div>
                <h2>
                  {{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.PARAMETRES'
                  | translate
                  | uppercase
                  }}
                </h2>
                <div class="mb4"> </div>
              </div>
            </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input m" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PHONE'
                  | translate
                  }}</span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="tel" prefix="+33 " [mask]="masks.PHONE"
                  [validation]="true"/>
                  <mat-error class="matError" *ngIf="addEditUser.controls['tel'].invalid">
                    {{"APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ERROR_PHONE" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "!data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="45" class="label text-bold">
                  {{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_RIGHTS'
                  | translate
                  }}
                </span>
                <button mat-icon-button class="gestion-droits">
                  <mat-icon [svgIcon]="icons.eye" (click)="openUserRights()"></mat-icon>
                </button>
              </div>
            </div>

            <div *ngIf= "data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="45">
                  <mat-select formControlName="onglet">
                    <mat-option *ngFor="let onglet of onglets" [value]="onglet.value">
                      {{ onglet.display | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input mm" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_FAX'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="57">
                  <input matInput type="text" formControlName="fax" prefix="+33 " [mask]="masks.PHONE"
                  [validation]="true"/>
                  <mat-error class="matError" *ngIf="addEditUser.controls['fax'].invalid">
                    {{"APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ERROR_FAX" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "!data.ifAdd" class="input groupes" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="45" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_GROUPS'
                  | translate
                  }}
                </span>
                <button mat-icon-button class="gestion-droits">
                  <mat-icon [svgIcon]="icons.group"></mat-icon>
                </button>
              </div>
            </div>

            <div *ngIf= "data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="30" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.MODE_SAISIE'
                  | translate
                  }}
                </span>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  
                  <div fxLayout="row" fxLayoutGap="3px" class="check">
                    <button class="button others" (click)="vtwo =! vtwo">
                      <mat-icon *ngIf="!vtwo"></mat-icon>
                      <mat-icon *ngIf="vtwo" [svgIcon]="icons.check"></mat-icon>
                    </button>
                    <h3>FullWebV2</h3>
                  </div>
  
                  <div fxLayout="row" fxLayoutGap="3px" class="check">
                    <button class="button others" (click)="java =! java">
                      <mat-icon *ngIf="!java"></mat-icon>
                      <mat-icon *ngIf="java" [svgIcon]="icons.check"></mat-icon>
                    </button>
                    <h3>Java</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div fxLayout="row" fxLayoutGap="75px">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">
                  {{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_TIMEZONE'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="35">
                  <mat-select formControlName="timezone">
                    <mat-option *ngFor="let zone of timeZone" [value]="zone">
                      {{ zone }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
  
            <div *ngIf= "!data.ifAdd" class="input groupes" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="45" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_RATTACHEMENT'
                  | translate
                  }}
                </span>
  
                <button class="button">
                  <mat-icon>PA</mat-icon>
                </button>
              </div>
            </div>
          </div>
  
          <!-- <div fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_COUNTRY'
                  | translate
                  }}
                </span>
                <app-country fxFlex="54" [countryStr]="country.value" (country)="getCountry($event)"></app-country>
              </div>
            </div>
          </div> -->
  
          <div *ngIf= "!data.ifAdd" fxLayout="row">
            <div>
              <h2>
                {{
                'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.PARAMETRES'
                | translate
                | uppercase
                }}
              </h2>
              <div class="mb4"> </div>
            </div>
          </div>
  
          <div fxLayout="row">
            <div *ngIf= "!data.ifAdd" class="input" fxLayout="column" fxFlex="50">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="10" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.DEFAULT_TAB'
                  | translate
                  }}
                </span>
                <mat-form-field class="input-field" appearance="outline" fxFlex="45">
                  <mat-select formControlName="onglet">
                    <mat-option *ngFor="let onglet of onglets" [value]="onglet.value">
                      {{ onglet.display | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
  
          <div *ngIf= "!data.ifAdd" fxLayout="row">
            <div class="input" fxLayout="column" fxFlex="25">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex="30" class="label text-bold">{{
                  'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.MODE_SAISIE'
                  | translate
                  }}
                </span>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
  
                  <div fxLayout="row" fxLayoutGap="3px" class="check">
                    <button class="button others" (click)="vtwo =! vtwo">
                      <mat-icon *ngIf="!vtwo"></mat-icon>
                      <mat-icon *ngIf="vtwo" [svgIcon]="icons.check"></mat-icon>
                    </button>
                    <h3>FullWebV2</h3>
                  </div>
  
                  <div fxLayout="row" fxLayoutGap="3px" class="check">
                    <button class="button others" (click)="java =! java">
                      <mat-icon *ngIf="!java"></mat-icon>
                      <mat-icon *ngIf="java" [svgIcon]="icons.check"></mat-icon>
                    </button>
                    <h3>Java</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      

      <div fxLayout="row-reverse" fxLayoutAlign="space-between center" footer>
        <app-button size="medium" *ngIf="!addEditUser.value || !addEditUser.valid" 
                    [disabled]="!addEditUser.value || !addEditUser.valid" 
                    class="btn-padding">
          <mat-icon [svgIcon]="icons.save"></mat-icon>
        </app-button>
        <app-button size="medium" (click)="onConfirm()" *ngIf="addEditUser.value && addEditUser.valid" 
                    class="btn-padding">
          <mat-icon [svgIcon]="icons.save"></mat-icon>
        </app-button>
      </div>
      <br>
    </div>
  </app-dialog>
  
  