import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AdminUserResponse , AdminUserRightsResponse} from '../models/api/responses/adminUser.response';
import { AdminUserRequest, AdminUserRightsRequest, AdminLinkUserGroupRequest, UpdateUserProfileRequest } from '../models/api/requests/adminUser.request';
import { AdminUserCreateRequest, AdminUserDeleteRequest } from '../models/api/requests/adminUserCreate.request';
import { AssignPaToUserRequest } from '../models/api/requests/AssignPaToUserRequest';
import { DefaultResponse } from '../models/api/responses/default.response';
@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private readonly apiService: ApiService) { }

  /**
   * Get list of favoris
   * @returns array of all consulations
   */
  adminUser(params: AdminUserRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, params, 'administration/utilisateurs_list');
  }
  
  addUser(adminUserCreateRequest: AdminUserCreateRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, adminUserCreateRequest, 'administration/utilisateurs_add');
  }
  deleteUser(adminUserDeleteRequest: AdminUserDeleteRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, adminUserDeleteRequest, 'administration/utilisateurs_delete');
  }

  assignPaToUser(assignPaToUserRequest: AssignPaToUserRequest): Observable<AdminUserResponse> {
    return this.apiService.post(AdminUserResponse, assignPaToUserRequest, 'affecter_utilisateur_pa');
  }

  
  getUserAffectedRights(params: AdminUserRightsRequest): Observable<AdminUserRightsResponse> {
    return this.apiService.post(AdminUserRightsResponse, params, 'liste_droits_par_utilisateur');
  }
  assignUserToGroup(request: AdminLinkUserGroupRequest ): Observable<AdminUserResponse> {
    return this.apiService.post<AdminUserResponse>(AdminUserResponse, request, 'group_edit_link_user');
  }

  updateUserProfile(request: UpdateUserProfileRequest ): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, request, 'update_user_profile');
  }
}

