import { EmailPaItem } from './PaEmailItem.model';

export class EntDemCompItem {

    // tslint:disable:variable-name

    public id = '';

    public cle_demande = '';

    public cle_dce = '';

    public cle_utilisateur = '';

    public email_utilisateur = '';

    public libelle = '';

    public date_envoi = '';

    public date_cloture = '';

    public nb_reponse = '';

    public emplacement = '';

    public fichier = '';

    public extension = '';

    public md5_fichier = '';

    public type_echange = '';

    public papier_auth = '';

    public type = '';

    public etat = '';

    public lrar = '';

    public cle_entreprise = '';

    public denomination_ent = '';

    public email_destinataire!: Array<EmailPaItem> | string;
    // public email_destinataire!: string;

    public date_acces = 0;

    public date_refus = 0;

    public date_ouverture = 0;

    public code_acces = '';

    public envoi_recap = '';

    public reponses_demande_comp!: ReponseDcItem[];

    public date_envoi_f = '';

    public date_cloture_f = '';

    public date_acces_f = '';

    public date_refus_f = '';

    public date_ouverture_f = '';

    public envoi_demande_comp!: EnvoiDcItem[];

}

export class ReponseDcItem {
    public id_reponse = '';
    public cle_reponse = '';
    public cle_demande = '';
    public cle_dce = '';
    public cle_entreprise_ms = '';
    public denomination_ent = '';
    public date_validation_cu = '';
    public date_acces_reponse = '';
    public date_depot = '';
    public date_ouverture = '';
    public cle_utilisateur_ouverture = '';
    public etat = '';
    public emplacement = '';
    public nom_fichier = '';
    public md5 = '';
    public md5_chiffre = '';
    public taille_reponse = '';
    public hd = 0;
    public etat_dl_openpp = 0;
    public contact = '';
    public email_contact = '';
    public JAVA_installed = '';
    public JAVA_jceerror = '';
    public JAVA_version = '';
    public JAVA_spec = '';
    public JAVA_home = '';
    public OS = '';
    public OS_version = '';
    public langage = '';
    public certificats = '';
    public date_validation_cu_f = '';
    public date_acces_reponse_f = '';
    public date_depot_f = '';
    public date_ouverture_f = '';
}

export class EnvoiDcItem {
    public id = 0;

    public cle_demande_envoi = '';

    public cle_utilisateur = '';

    public cle_etablissement = '';

    public reference_dce = '';

    public nom_utilisateur = '';

    public ate_envoi = '';

    public lrar = 0;

    public reference_lrar = '';

    public certification = '';

    public date_envoi_f = '';

}

// tslint:enable:variable-name
