import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';

@Component({
  selector: 'app-marche-container',
  templateUrl: './marches-container.component.html',
  styleUrls: ['./marches-container.component.scss']
})
export class MarchesContainerComponent implements OnInit {

  @ViewChildren('footer') footerElt!: QueryList<ElementRef>;
  @ViewChildren('customContent') customContentElt!: QueryList<ElementRef>;

  @Input()
  context!: 'pa' | 'attri' | 'de';

  @Input()
  closureDate!: Date;

  @Input()
  etatConsultation!: number;

  @Input()
  color!: string;

  constructor() { }

  ngOnInit(): void {
  }

  get statusColorClass(): string {
        return this.color;
  }

  get printClock(): boolean {
    return this.etatConsultation === 5;
  }

  get displayFooter(): boolean {
    return this.footerElt ? this.footerElt.first.nativeElement.children?.length > 0 : false;
  }

  get displayCustomContent(): boolean {
    return this.customContentElt ? this.customContentElt.first.nativeElement.children?.length > 0 : false;
  }

  get isPaSide(): boolean {
    return this.context === 'pa';
  }

  get displayHeaderShape(): boolean {
    return !(['pa', 'attri'].includes(this.context));
  }

}
