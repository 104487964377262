import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RoutingService } from '@atline/core/services/routing.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ms-nav',
  templateUrl: './ms-nav.component.html',
  styleUrls: ['./ms-nav.component.scss'],
})
export class MsNavComponent implements OnInit {
  public isLogged = false;

  currentRoute = '';

  public navElement = [{ url: this.routingService.firstFragment }];

  isPa!: Observable<boolean>;

  activePa = environment.activation.pa;


  constructor(
    private readonly router: Router,
    private readonly globalData: GlobalDataService,
    private readonly utilsService: UtilsService,
    private readonly routingService: RoutingService
  ) {
    this.isPa = globalData.isPa;
    this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const urlSegment = event.url.split('/')[2];
      this.currentRoute = !urlSegment || urlSegment === 'consultation' ? '' : urlSegment;
    });  
  }

  ngOnInit(): void {
    this.globalData.isLogged.subscribe((isLogged) => {
      this.isLogged = isLogged;
    });
  }

  get isConnected(): boolean {
    return this.utilsService.isLoggedWithAuthorizationCode;
  }
}
