import { environment } from './../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DialogsService } from '../services/dialogs.service';
import { Debounce } from '../decorators/debounce.decorator';
import { Router } from '@angular/router';
import { RoutingService } from '../services/routing.service';
import { timeout } from 'rxjs/operators';

const blackListPath = ['atst/structure/attestations_obligatoires', 'logo_pa'];
const CODE_500 = 500;

@Injectable()
export class HttpResponseTimeLogger implements HttpInterceptor {


  constructor(
    private readonly dialogServices: DialogsService,
    private readonly router: Router,
    private readonly routingService: RoutingService
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let timeoutValue: any = req.headers.get('timeout');
    if (timeoutValue)
      timeoutValue = parseInt(timeoutValue, 10);    

    let newHeader = req.headers
    newHeader = newHeader.delete('timeout');
    
    const request = req.clone({ headers: newHeader });

    return next.handle(request).pipe(
      timeout(timeoutValue ?? 20000000),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: any) => {
        const isWsRequest = req.url?.includes(environment.api);
        if (isWsRequest) {

          console.log(error);


          if (error.name === 'TimeoutError' || error.status === 0) {
            this.router.navigate([this.routingService.firstFragment, `non-disponible`])
          }




          // if (error.status === 0) {
          //   this.router.navigate([this.routingService.firstFragment, RoutingEnum.MARCHES_SECURISES, `non-disponible`])
          //   // return throwError('Timeout Exception');
          // }
          // else {
          //   console.log(error);
          //   console.log(error.error.error === HttpErrorEnum.INVALID_TOKEN);


          // if (error.error.error === HttpErrorEnum.INVALID_TOKEN) {
          //   this.authService.generateTokenForClient().subscribe({
          //     next: (res) => {
          //       this.authService.setSessionData(res);
          //       this.authService.setLocalData(res);
          //       this.globalDataService.setToken(res.access_token);
          //       next.handle(req);
          //       // s.unsubscribe();
          //     }
          //   });
          // }


          switch (environment.env) {
            case 'dev':
              // this.matDialog.open(WsErrorDialogComponent, {
              //     data: { error: error.error.error_description }
              // });
              if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
                // alert('--- WS error --- \n\n' + error.error.error_description);
                if (error.status !== CODE_500) {
                  alert(error.url + '\n' + error.status + '\n' + error.error.error_description);
                } else {
                  this.openDialogError(error);
                }
              }
              console.log(error);
              break;

            case 'loc':
              if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
                // alert('--- WS error --- \n\n' + error.error.error_description);
                if (error.status !== CODE_500) {
                  alert(error.url + '\n' + error.status + '\n' + error.error.error_description);
                } else {
                  this.openDialogError(error);
                }
              }
              console.log(error);
              break;

            case 'ppprd':
              // if (!_.find(blackListPath, (p) => `${environment.api}${p}` === `${error.url}`)) {
              //   // alert('--- WS error --- \n\n' + error.error.error_description);
              //   if (error.status !== CODE_500) {
              //     alert(error.url + '\n' + error.error.error_description);
              //   } else {
              //     this.openDialogError(error);
              //   }
              // }
              console.log(error);
              break;

            case 'prd':
              // if (error.status === CODE_500) {
              //   this.dialogServices.createInfoDialog('', `APP.DIALOG.ERRORS.CODE_${error.status}`, 'APP.CLOSE', true);
              // }
              break;
            default:
              break;
          }
          return throwError(error);
          // }

        } else {
          return throwError(error);
        }

      })


    );
  }

  @Debounce(200)
  private openDialogError(error: HttpErrorResponse): void {
    this.dialogServices.createInfoDialog('', `APP.DIALOG.ERRORS.CODE_${error.status}`, 'APP.CLOSE', true);
  }
}
