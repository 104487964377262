<div class="container" fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <h3 class="text-bl">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PA.TITLE' | translate : {user: data.user.firstName + " " + data.user.lastName} | uppercase }}
    </h3>
  </div>

  <div class="body" fxLayout="column">

    <div class="actions-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <span>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PA.ASSIGN_ALL' | translate }}</span>
        <button mat-stroked-button class="button plus" (click)="checkAll('assign')" fxLayoutAlign="center center">
          <mat-icon [svgIcon]="icons.plus"></mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <span>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PA.UNASSIGN_ALL' | translate }}</span>
        <button mat-stroked-button class="button plus" (click)="checkAll('unassign')" fxLayoutAlign="center center">
          <mat-icon class="moins">-</mat-icon>
        </button>
      </div>
      <!-- <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
        <button mat-stroked-button class="button proc">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PA.ADD_PROC' | translate }}</span>
            <mat-icon [svgIcon]="icons.plus"></mat-icon>
          </div>
        </button>
      </div> -->

    </div>


    <table class="table" fxFlex="100">
      <tbody>
        <tr *ngFor="let pa of pasList">
          <td>{{ pa.denomination_pa }}</td>
          <td>
            <button class="button others" (click)="checkClicked(pa.cle_pa)">
              <mat-icon *ngIf="!checkedPa[pa.cle_pa]"></mat-icon>
              <mat-icon *ngIf="checkedPa[pa.cle_pa]" [svgIcon]="icons.check"></mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>