import { NgIfContext } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AffectedUserItem } from '@atline/core/models/userItem.model';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { AdminUserResponse } from '@atline/core/models/api/responses/adminUser.response';
import { UtilsService } from '@atline/core/services/utils.service';
import { AdminLinkUserGroupRequest, UpdateUserProfileRequest } from '@atline/core/models/api/requests/adminUser.request';
import { GroupsService } from '@atline/core/services/groups.service';
import { GroupListResponse } from '@atline/core/models/api/responses/groupList.response';
import { GroupItem } from '@atline/core/models/groupItem.model';
import { DefaultResponse } from '@atline/core/models/api/responses/default.response';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent implements OnInit {
  users: AffectedUserItem[];
  groups: GroupItem[];
  isDeleteDisabled: boolean = false;
  selectedUser: any = null;
  selectedGroup: any = null;
  private userListAdmin!: AdminUserResponse;
  private groupListAdmin!: GroupListResponse;
  emptyList: TemplateRef<NgIfContext<boolean>> | null = null;
  displayedColumns: string[] = ['firstName', 'lastName', 'actions'];
  displayedColumnsUser: string[] = ['nom', 'actions']; 
  isLoading = false;
  user: any; 
  group: any; 

  constructor(
    private readonly adminUserService: AdminUserService,
    private readonly adminGroupService: GroupsService,
    private readonly utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    readonly dialogRef: MatDialogRef<UsersDialogComponent>
  ) {
    this.users = data.users || [];
    this.groups = data.groups || [];
    if(data.group){
      this.group = data.group; 
    }
    if(data.user){
      this.user = data.user;
    }
  }

  ngOnInit(): void {
    this.updateDeleteDisabledState();
    this.loadUsers();
  }

  private loadUsers(): void {
    this.isLoading = true;
    if(this.group){
      this.adminUserService.adminUser({
        cle_etab: this.utils.cleEtab || '',
        cle_ent: this.utils.cleEnt || ''
      }).subscribe({
        next: (response) => {
          this.userListAdmin = response;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
    }
    if(this.user){
      this.adminGroupService.getGroups({
        cle_etab: this.utils.cleEtab || '',
      }).subscribe({
        next: (response) => {
          this.groupListAdmin = response;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
    }

  }

  get availableUsers() {
    const allUsers = this.userListAdmin?.utilisateurs || [];
    
    return allUsers.filter(user => 
      !this.users.some(existingUser => 
        existingUser.id_ident === user.idIdent
      )
    );
  }

  
  get availableGroups() {
    const allGroups = this.groupListAdmin?.groups || [];
    return allGroups.filter(group => !this.groups.some(existingGroup => 
        existingGroup.cle_gr === group.cle_gr
      )
    );
  }

  private updateDeleteDisabledState(): void {
    if(this.group){
      this.isDeleteDisabled = this.users.length === 0;
    }
    if(this.user){
    this.isDeleteDisabled = this.groups.length === 0;

    }
  }

  deleteUserGroupLink(user: AffectedUserItem | null ,group: GroupItem | null ): void {
    if(this.group && user){
      const request: AdminLinkUserGroupRequest = {
        cle_etab: this.utils.cleEtab || '',
        cle_pa: this.utils.clePa || '',
        cle_groupe: this.group.cle_gr,
        id_ident: user.id_ident
      };
      this.adminUserService.assignUserToGroup(request).subscribe({
        next: (_response) => {
          this.users = this.users.filter(u => u.id_ident !== user.id_ident);
          this.updateDeleteDisabledState();
          this.data.groupList.callToWs();
          const req = new UpdateUserProfileRequest();
          req.cle_etab = this.utils.cleEtab || '';
          req.cle_utilisateur = user.id_ident;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        },
        error: (error) => {
          console.error('Erreur lors du détachement de l\'utilisateur du groupe:', error);
        }
      });
    }
    if(this.user && group){
      const request: AdminLinkUserGroupRequest = {
        cle_etab: this.utils.cleEtab || '',
        cle_pa: this.utils.clePa || '',
        cle_groupe: group.cle_gr,
        id_ident: this.user.id_ident
      };
      this.adminUserService.assignUserToGroup(request).subscribe({
        next: (_response) => {
          this.groups = this.groups.filter(u => u.cle_gr !== group.cle_gr);
          this.updateDeleteDisabledState();
          this.data.userList.callToWs();
          const req = new UpdateUserProfileRequest();
          req.cle_etab = this.utils.cleEtab || '';
          req.cle_utilisateur = this.user.id_ident;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        },
        error: (error) => {
          console.error('Erreur lors du détachement de l\'utilisateur du groupe:', error);
        }
      });
    }
  }

  addUserGroupLink(): void {
    if (this.selectedUser && this.group) {
      const request: AdminLinkUserGroupRequest = {
        cle_etab: this.utils.cleEtab || '',
        cle_pa: this.utils.clePa || '',
        cle_groupe: this.group.cle_gr,
        id_ident: this.selectedUser.idIdent
      };
      this.adminUserService.assignUserToGroup(request).subscribe({
        next: (_response) => {
          const newUser: AffectedUserItem = {
            firstName: this.selectedUser.firstName,
            lastName: this.selectedUser.lastName,
            id_ident: this.selectedUser.idIdent,
            cle_personne: this.selectedUser.cle_personne,
            cle_groupe: this.group.cle_gr,
            label: this.group.label,
          };
          
          this.users = [...this.users, newUser];
          this.selectedUser = null;
          this.updateDeleteDisabledState();
          this.data.groupList.callToWs();
          const req = new UpdateUserProfileRequest();
          req.cle_etab = this.utils.cleEtab || '';
          req.cle_utilisateur = this.selectedUser.idIdent;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        },
        error: (error) => {
          console.error('Erreur lors de l\'ajout de l\'utilisateur au groupe:', error);
        }
      });
    }
    if (this.selectedGroup && this.user) {
      const request: AdminLinkUserGroupRequest = {
        cle_etab: this.utils.cleEtab || '',
        cle_pa: this.utils.clePa || '',
        cle_groupe: this.selectedGroup.cle_gr,
        id_ident: this.user.id_ident
      };
      this.adminUserService.assignUserToGroup(request).subscribe({
        next: (_response) => {
          const newGroup: GroupItem = {
            cle_gr: this.selectedGroup.cle_gr,
            nom: this.selectedGroup.nom,
            cle_etablissement: this.selectedGroup.cle_etablissement,
            id: this.selectedGroup.id,
            id_element: this.selectedGroup.id_element,
          };
          this.groups = [...this.groups, newGroup];
          this.selectedGroup = null;
          this.updateDeleteDisabledState();
          this.data.userList.callToWs();
          const req = new UpdateUserProfileRequest();
          req.cle_etab = this.utils.cleEtab || '';
          req.cle_utilisateur = this.user.id_ident;
          req.contexte = 'set';
          this.adminUserService.updateUserProfile(req).subscribe();
        },
        error: (error) => {
          console.error('Erreur lors de l\'ajout de l\'utilisateur au groupe:', error);
        }
      });
    }
  }

  setUserProfile(id_ident: string): Observable<DefaultResponse> {
    const req = new UpdateUserProfileRequest();
    req.cle_etab = this.utils.cleEtab || '';
    req.cle_utilisateur = id_ident;
    req.contexte = 'set';
  
    return this.adminUserService.updateUserProfile(req);
  }
  // this.adminUserService.setUserProfile('12345').subscribe();


  get userList() {
    return this.userListAdmin.utilisateurs ?? [];
  }

  get groupsList() {
    return this.groupListAdmin.groups ?? [];
  }
}

