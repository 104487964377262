import * as _ from "lodash";
import { AccessConsultation, BaseConsultation } from "./BaseConsultation.model";

export class ConsultationOeItem extends BaseConsultation {

  private favoris!: boolean;
  private code_acces!: string | null;
  private cle_ent!: string;
  private dceTimezone!: string;

  constructor(consultation: any) {
    super(consultation);
    this.favoris = !_.isNil(consultation.favoris) ? (consultation.favoris == 1) ? true : false : false;
    this.code_acces = !_.isNil(consultation.passe) ? !_.isEmpty(consultation.passe) ? consultation.passe : null : null;
    this.cle_ent = !_.isNil(consultation.cle_ent) ? !_.isEmpty(consultation.cle_ent) ? consultation.cle_ent : null : null;
    this.dceTimezone = !_.isNil(consultation.dceTimeZone) ? !_.isEmpty(consultation.dceTimeZone) ? consultation.dceTimeZone : null : null;
  }

  get isOnFavoris(): boolean {
    return this.favoris
  }

  get cleEntreprise(): string | null {
    return this.cle_ent;
  }

  get access(): AccessConsultation {
    return {
      ...this.accessBase,
      code: this.code_acces
    }
  }

  get timezone() {
    return this.dceTimezone;
  }


}
