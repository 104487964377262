import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { configEtabListRequest } from '../models/api/requests/configEtabListRequest';
import { assignDeleteConfigEtabRequest } from '../models/api/requests/assignDeleteConfigEtabRequest';
import { ConfigEtabResponse } from '../models/api/responses/configEtab.response';

@Injectable({
  providedIn: 'root'
})
export class ConfigEtabService {

  constructor(
    private readonly apiService: ApiService
  ) {}

  getConfigEtab(configEtabListRequest: configEtabListRequest): Observable<ConfigEtabResponse> {
    return this.apiService.post(ConfigEtabResponse, configEtabListRequest, 'configuration_etab_list');
  }
  assignConfigEtab(assignConfigEtabRequest: assignDeleteConfigEtabRequest): Observable<ConfigEtabResponse> {
    return this.apiService.post(ConfigEtabResponse, assignConfigEtabRequest, 'configuration_etab_add');
  }
  deleteConfigEtab(deleteConfigEtabRequest: assignDeleteConfigEtabRequest): Observable<ConfigEtabResponse> {
    return this.apiService.post(ConfigEtabResponse, deleteConfigEtabRequest, 'configuration_etab_remove');
  }
}
