import { Component, OnInit } from '@angular/core';
import { UtilsService } from './core/services/utils.service';
import { SessionStorage } from './shared/enums/session-storage.enum';
import { IconService } from './core/services/icon.service';
import { AuthenticateService } from './core/services/authenticate.service';
import { GlobalDataService } from './core/services/data-service.service';
import { TokenService } from './core/services/token.service';
import * as _ from 'lodash';
import { forkJoin, of, iif } from 'rxjs';
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeoService } from './core/services/seo.service';
import { SseService } from './core/services/sse.service';
import { Router } from '@angular/router';
import { RoutingService } from './core/services/routing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  public isInitialized = false;
  phase = environment.env;
  // private interval!: any;

  // clear localstorage and delete de token when ATS is closing

  constructor(
    private readonly utilsService: UtilsService,
    private readonly authService: AuthenticateService,
    private readonly iconService: IconService,
    private readonly globalDataService: GlobalDataService,
    private readonly tokenService: TokenService,
    private readonly seoService: SeoService,
    private readonly sseService: SseService,
    private readonly router: Router,
    private readonly routingService: RoutingService
  ) {
    this.seoService.setTile('SEARCH');
    globalDataService.setIsInitialized(false);
    globalDataService.getIsInitialized().subscribe(res => this.isInitialized = res);
  }

  ngOnInit(): void {
    const cookies = this.utilsService.getCookies();

    if (cookies === '' || cookies === null || cookies === undefined) {
      this.displayCookiesBanner = true;
    }
    this.initializeApp();

    // Get user session from sessionStorage
    const userSession = this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true);
    
    if (userSession && userSession.profile && userSession.profile.user) {
      const id_ident = userSession.profile.user.id_ident; // Extract id_ident
      const accessToken = userSession.access_token; // Extract token for getTokenInfo      
      // Start SSE connection and listen for events
      this.sseService.connect(id_ident, accessToken);
    }

  }

  initializeApp(): void {
    this.iconService.registerIcons();
    // {
    //   path: 'marches-securises', component: MarchesSecurisesComponent,
    //   loadChildren: async () => (await import('./marches-securises/marches-securises.module')).MarchesSecurisesModule
    // },
    // {path: '', redirectTo: 'marches-securises', pathMatch: 'full'},
    // {path: '**', redirectTo: 'marches-securises', pathMatch: 'full'}  

    if (this.router.url !== `${this.routingService.firstFragment}/redirect-sso`) {
      this.initializeToken();
    }
  }

  private initializeToken(): void {
    
    if ((_.isEmpty(this.utilsService.tokenLocalStorage) || _.isNil(this.utilsService.tokenLocalStorage)) && _.isNil(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION)?.access_token)) {
      this.utilsService.setLocalStorage(SessionStorage.USER_SESSION, JSON.stringify({t: this.utilsService.getSessionStorage(SessionStorage.USER_SESSION)?.access_token}));
    }
    forkJoin({
      token: of(this.utilsService.tokenLocalStorage),
      session: of(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION)),
    }).pipe(
      mergeMap(({ token, session }) => iif(
        () => !!token && _.isNil(session),
        this.authService.getTokenInfo(token as string).pipe(
          tap(res => {
            this.authService.setSessionData(res);
            this.utilsService.clearLocalStrorage();
            this.authService.setLocalData(res);
            this.globalDataService.setToken(res.access_token);
            this.globalDataService.setIsLogged(true);
            this.globalDataService.setIsInitialized(true);
          }),
          map(res => ({ token, session: res })),
          catchError(() => of({ token: undefined, session: undefined }))
        ),
        of({ token, session })
      )),
      mergeMap(({ token, session }) => iif(
        () => _.isNil(token) && _.isNil(session),
        this.authService.generateTokenForClient().pipe(tap(res => {
          this.authService.setSessionData(res);
          this.authService.setLocalData(res);
          this.globalDataService.setToken(res.access_token);
          this.globalDataService.setIsInitialized(true);
        }), map(res => ({ token: res.access_token, session: res }))),
        of({ token, session })
      )),
      tap(({ token, session }) => {
        if (_.isNil(token) && session) {
          this.utilsService.setLocalStorage('session', JSON.stringify({ t: session.access_token }));
          if (this.utilsService.isLoggedWithAuthorizationCode) {
            this.globalDataService.setIsLogged(true);
          }
          this.authService.setLocalData(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true));
          this.globalDataService.setIsInitialized(true);
        }
      }),
      tap(({ token, session }) => {        
        if (token && this.utilsService.isTokenValid() && !_.isNil(session)) {
          this.globalDataService.setToken(token);
          if (this.utilsService.isLoggedWithAuthorizationCode) {
            this.globalDataService.setIsLogged(true);
            this.globalDataService.setIsInitialized(true);
          }
          this.globalDataService.setIsInitialized(true);
        }
      }),
      tap(({ token }) => {
        if (!this.utilsService.isTokenValid() && token) {
          this.globalDataService.setIsInitialized(true);
        }
      }),
      
      catchError(() => {
        this.globalDataService.setIsInitialized(true);
        return of(null)
      }),
    ).subscribe(() => {
      this.tokenService.verifyTokenValidity()
    });
  }

  displayCookiesBanner: boolean = false;


  onActivate(): void {
    window.scroll(0, 0);
  }
}
