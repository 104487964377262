import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectMsV3Helper } from '@atline/core/helpers/redirectMsv3.helper';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { GlobalDataService } from '@atline/core/services/data-service.service';
import { RoutingService } from '@atline/core/services/routing.service';
import { SsoService } from '@atline/core/services/sso.service';
// import { TokenService } from '@atline/core/services/token.service';
import { UtilsService } from '@atline/core/services/utils.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  error = false;
  secondsBeforeRedirection = 5;
  loading = true;

  constructor(
    private readonly ssoService: SsoService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticateService: AuthenticateService,
    private readonly router: Router,
    // private readonly tokenService: TokenService,
    private readonly utilService: UtilsService,
    private readonly globalService: GlobalDataService,
    private readonly routingService: RoutingService,
    private readonly redirectionHelper: RedirectMsV3Helper
  ) { }

  ngOnInit(): void {

    const code = this.activatedRoute.snapshot.queryParams.code;
    const token = this.activatedRoute.snapshot.queryParams.token;
    const module = this.activatedRoute.snapshot.queryParams.module;
    const params = this.activatedRoute.snapshot.queryParams.params;

    if (code)
      this.ssoService.token({
        client_id: environment.sso.microsoft.clientId,
        client_secret: environment.sso.microsoft.clientSecret,
        code: code,
        grant_type: 'authorization_code',
        redirect_uri: environment.sso.microsoft.redirectUri
      }).subscribe({
        next: (res) => {
          this.loading = false
          if (res.access_token) {
            this.authenticateService.setData(res);
          } else {
            this.error = true;
            this.redirectToHomePage();
          }

        },
        error: () => {
          this.loading = false
          this.error = true;
          this.redirectToHomePage();
        }
      })

    if (token) {
      this.authenticateService.getTokenInfo(token).pipe().subscribe(res => {
        this.authenticateService.setSessionData(res);
        this.utilService.clearLocalStrorage();
        this.authenticateService.setLocalData(res);
        this.globalService.setToken(res.access_token);
        this.globalService.setIsLogged(true);
        // this.tokenService.verifyTokenValidity();
        this.globalService.setIsInitialized(true);

        const redirectData = this.redirectionHelper.v2ToV3(module, params)

        if (redirectData) {
          this.router.navigate([this.routingService.firstFragment, ...redirectData])
        } else {
          this.router.navigate([this.routingService.firstFragment])
        }

      })
    } else if (_.isNil(token) || _.isEmpty(token)) {
      this.router.navigate(['/']);
    }
  }
  redirectToHomePage(): void {

    const interval = setInterval(() => {
      this.secondsBeforeRedirection -= 1;
    }, 1000)

    setTimeout(() => {
      clearInterval(interval);
      this.router.navigate(['/']);
    }, 5000)
  }


}
