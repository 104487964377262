import { catchError } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { AttrbutaireByBatchesitem, Attributaire } from '@atline/core/models/api/responses/attributaire.reponse';
import { AttributaireService } from '@atline/core/services/attributaires.service';
import { throwError } from 'rxjs';
import * as _ from 'lodash';
import { ConsultationsService } from '@atline/core/services/consultations.service';
import { ConsulterTitulaireRequest, CreateSemRequest } from '@atline/core/models/api/requests/consultation.request';
import { Router } from '@angular/router';
import { RoutingService } from '@atline/core/services/routing.service';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { MsRightsService } from '@atline/core/services/ms-rights.service';
import { FormControl } from '@angular/forms';
import { Icons } from '@atline-shared/enums/icons.enum';
import { GroupItem } from '@atline/core/models/groupItem.model';

interface DataDialog {
  cleDce: string;
  cleEtab: string;
  clePa: string;
  hasLot: boolean;
  groups: Array<any>;
  codeRestreinte: string;
  context: 'inform' | 'sem';
}

export interface AttributaireWithSelect extends Attributaire {
  isSelected: boolean;
}

export interface GroupWithSelect extends GroupItem {
  isSelected: boolean;
}

@Component({
  selector: 'app-consultation-holder',
  templateUrl: './consultation-holder.component.html',
  styleUrls: ['./consultation-holder.component.scss']
})
export class ConsultationHolderComponent implements OnInit {

  private attributaire!: Array<AttrbutaireByBatchesitem | Attributaire>;
  private errors: { attributaire?: ApiErrorResponse } = {};
  private loading = { attributaires: false, sendData: false };
  attributaireSelected!: AttributaireWithSelect[];
  groups!: Array<GroupWithSelect>;
  duplicateGroup = true;

  public selectedBatche = new FormControl(null);
  batches: { cle: string, libelle: string }[] = [];

  icons = Icons;

  constructor(
    private readonly router: Router,
    private readonly attributaireService: AttributaireService,
    private readonly consultationService: ConsultationsService,
    private readonly msRightService: MsRightsService,
    public dialogRef: MatDialogRef<ConsultationHolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    private readonly routingService: RoutingService
  ) { }

  ngOnInit(): void {
    this.loading.attributaires = true;
    this.attributaireService.getAttriburaireByBatches({
      cle_dce: this.data.cleDce,
      cle_etab: this.data.cleEtab,
      cle_pa: this.data.clePa,

    }).subscribe({
      next: (res) => {
        this.groups = res.groupes?.map(g => { return { ...g, isSelected: false } });
        if (this.data.hasLot) {
          this.attributaire = res.data as AttrbutaireByBatchesitem[];
          this.batches = (<Array<AttrbutaireByBatchesitem>>this.attributaire).map(a => { return { cle: a.cle_lot, libelle: a.libelle_lot } });
        } else {
          this.attributaire = res.data as Attributaire[];
          this.attributaireSelected = <AttributaireWithSelect[]>this.attributaire.map(a => { return { ...a, isSelected: true } });
        }
        this.loading.attributaires = false;
      },
      error: () => {
        this.loading.attributaires = false;
      }
    });

    this.selectedBatche.valueChanges.subscribe(res => {
      const data = <AttrbutaireByBatchesitem | undefined>this.attributaire.find(a => a.cle_lot === res);
      if (data)
        this.attributaireSelected = <AttributaireWithSelect[]>data.attributaires.map(a => { return { ...a, isSelected: true } });
    })
  }

  get hasAtrributaires(): boolean {
    return this.attributaire.length > 0;
  }

  get isLoading(): boolean {
    return this.loading.attributaires;
  }

  get hasGroups(): boolean {
    return this.groups?.length > 0;
  }

  get isSemContext() {
    return this.data.context === 'sem';
  }

  get canUseSem() {
    return this.msRightService.getCanUseSem(this.data.clePa);
  }

  get attributaireData() {
    if (this.data.hasLot)
      return (<any>this.attributaire).attributaires;
    return this.attributaire
  }

  get displaySendBtn() {
    return this.data.hasLot ? this.selectedBatche.value : this.hasAtrributaires ?? true;
  }

  get displayGoToBtn() {
    return false
  }

  sendRequest(): void {

    this.loading.sendData = true;
    if (this.data.context === 'inform') {
      const request = new ConsulterTitulaireRequest();
      request.cle_etab = this.data.cleEtab;
      request.cle_pa = this.data.clePa;
      request.cle_parent_dce = this.data.cleDce;
      request.cles_attributaires = this.attributaireSelected.filter(a => a.isSelected === true).map(at => at.cle).join(';');
      if (this.groups?.length > 0)
        request.cles_groupes = this.groups.filter(g => !g.isSelected).map(g => g.cle_gr).join(';')

      if (this.selectedBatche.value)
        request.cle_parent_lot = this.selectedBatche.value

      this.consultationService.postConsulterTitulaire(request).pipe(
        catchError(err => {
          this.loading.sendData = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) this.errors.attributaire = apiError;
          return throwError(err);
        })
      ).subscribe(resSend => {
        this.loading.sendData = false;
        this.dialogRef.close();
        this.router.navigate([this.routingService.firstFragment, RoutingEnum.PA, RoutingEnum.CONSULTATION, RoutingEnum.EDITION, resSend.cle_dce]);
      });
    } else {
      const request: CreateSemRequest = {
        cle_attributaires: this.attributaireSelected.filter(a => a.isSelected).map(at => at.cle).join(';'),
        cle_etab: this.data.cleEtab,
        cle_pa: this.data.clePa,
        cle_dce: this.data.cleDce
      }

      if (this.selectedBatche.value)
        request.cle_lot = this.selectedBatche.value;

      if (this.groups?.length > 0)
        request.cles_groupes = this.groups.filter(g => !g.isSelected).map(g => g.cle_gr).join(';')

      this.consultationService.createSem(request).pipe(
        catchError(err => {
          this.loading.sendData = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) this.errors.attributaire = apiError;
          return throwError(err);
        })
      ).subscribe(resSend => {
        this.loading.sendData = false;
        this.dialogRef.close();
        this.router.navigate([this.routingService.firstFragment, RoutingEnum.PA, RoutingEnum.CONSULTATION, RoutingEnum.EDITION, resSend.cle_dce]);
      });
    }

  }

}
