import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AssignPaToUserRequest } from '@atline/core/models/api/requests/AssignPaToUserRequest';
import { AdminUserService } from '@atline/core/services/adminUser.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { Icons } from '@atline-shared/enums/icons.enum';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminUserItem } from '@atline/core/models/adminUser.model';
import { PaToken } from '@atline/core/models/api/responses/authenticate.response';
import { forkJoin, Subscription } from 'rxjs';
import { UsersListComponent } from '@atline/marches-securises/pa/pa-administrations/users/users-list/users-list.component';

@Component({
  selector: 'app-pa-user',
  templateUrl: './pa-user.component.html',
  styleUrls: ['./pa-user.component.scss']
})
export class PaUserComponent implements OnInit, OnDestroy {

  subs = new Subscription();
  pasList!: PaToken[];

  public checkedPa: { [key: string]: boolean } = {};
  icons = {
    plus: Icons.Plus,
    check: Icons.Check
  };


  constructor(
    private adminUserService: AdminUserService,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: { user: AdminUserItem, userList: UsersListComponent}
  ) {
    utils.paListFromToken.forEach(pa => {
      this.checkedPa[pa.cle_pa] = data.user.paList?.includes(pa.cle_pa) ?? false;
    });
    this.pasList = this.utils.paListFromToken;
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  checkClicked(cle: string) {
    this.checkedPa[cle] = !this.checkedPa[cle];
    const assignPaToUserRequest: AssignPaToUserRequest = {
      cle_etab: this.utils.cleEtab || '',
      cle_pa: cle,
      cle_utilisateur: this.data.user.idIdent
    };
    // this.subs.add(this.adminUserService
    //   .assignPaToUser(assignPaToUserRequest)
    //   .subscribe());

    this.subs.add(
      this.adminUserService.assignPaToUser(assignPaToUserRequest).subscribe(() => {
        this.data.userList.callToWs(); 
      })
    );  
      
  }

  checkAll(mode: 'assign' | 'unassign') {
    const tasks = this.utils.paListFromToken
      .filter(pa => {
        const isChecked = this.checkedPa[pa.cle_pa];
        return mode === 'assign' ? !isChecked : isChecked;
      })
      .map(pa => {
        this.checkedPa[pa.cle_pa] = !this.checkedPa[pa.cle_pa];
        const request: AssignPaToUserRequest = {
          cle_etab: this.utils.cleEtab || '',
          cle_pa: pa.cle_pa,
          cle_utilisateur: this.data.user.idIdent
        };
        return this.adminUserService.assignPaToUser(request);
      });
  
    // if (tasks.length) {
    //   this.subs.add(forkJoin(tasks).subscribe());
    // }
    if (tasks.length) {
      this.subs.add(
        forkJoin(tasks).subscribe(() => {
          this.data.userList.callToWs(); 
        })
      );
    }
  }
}
