import { AdminUserItem } from "../../adminUser.model";
import { GroupItem } from "../../groupItem.model";
import { Pagination } from "../../pagination.model";
// import { Pagination } from '../../pagination.model';


export class AdminUserResponse {

    // tslint:disable:variable-name

   // retourner tableau de user
   utilisateurs: AdminUserItem[] = [];
   nb_total = 0;


    // tslint:enable:variable-name

}

  export interface Right {
      id: string;
      id_droit: string;
      droit: string;
      libelle: string;
      date_debut_validite: string;
      date_fin_validite: string;
    }
  export interface Rights {
    rights: Right[];
    count: number;
  }
  
  export interface UserRights {
    data: Right[];
    nbr_totale: number;
    pagination: Pagination;
  }
  
  export class AdminUserRightsResponse {
    cle_utilisateur!: string;
    droits!: UserRights;   
  
  }

  export interface Groups {
    groups: GroupItem[];
    count: number;
  }
