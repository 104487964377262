import { GroupItem } from '../../groupItem.model';
import { Pagination } from '../../pagination.model';
export class GroupListResponse {

    // tslint:disable:variable-name
    groups: GroupItem[] = [];
    nb_total = 0;
    pagination!: Pagination;
}
    // tslint:enable:variable-name

