import { GroupListResponse } from '../models/api/responses/groupList.response';
import { Observable } from 'rxjs';
import { GroupListRequest } from './../models/api/requests/groupsList-request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { GroupItem } from '../models/groupItem.model';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(
    private readonly apiService: ApiService,
    private readonly utils: UtilsService
    ) { }

  getGroups(groupListRequest: GroupListRequest): Observable<GroupListResponse> {
    return this.apiService.post(GroupListResponse, groupListRequest, 'group_list');
  }

  getGroupsByKey(groupsKey: string): GroupItem | undefined {
    const groups = this.utils.getGroups('ms');
    return _.find(groups, ['cle_gr', groupsKey]);
  }
}
