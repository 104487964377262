<app-administration-wapper>
  <div title >
    <div *ngIf="!rights" class="text-bl">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TITLE' | translate: { nbUsers: nbUsers } }}
    </div>
    <div *ngIf="rights" class="text-bl">
      {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.RIGHTS_OF' | translate : { user: user.firstName } | uppercase }}
    </div>
  </div>

  <div headerAction>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
      <div fxLayout="row" fxLayoutAlign="start center" class="search-section">
        <label class="search label-input">
          {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.SEARCH_USERS' | translate }}
        </label>
        <input
          mat-input
          type="search"
          class="form-control"
          aria-label="Recherche"
          aria-describedby="search-addon"
          #searchInput
          (input)="searchUsers(searchInput.value)"
        />
        <button mat-icon-button (click)="searchUsers(searchInput.value)">
          <mat-icon svgIcon="loupe" class="search-icon"></mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="add-user">
        <label class="label-input">
          {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.ADD_USER_LABEL' | translate }}
        </label>
        <app-button
          [icon]="Icons.Plus"
          size="add"
          class="btn btn-primary add-user-btn"
          (click)="openFormUserDialog(user, true, users, this)"
        ></app-button>


      </div>
      
      <app-button
      *ngIf ="this.rights"
      [icon]="Icons.Plus"
      size="add"
      class="close"
      (click)="closeGrp()"
    ></app-button>
    </div>
  </div>

  <div body>
    <app-legend-user-admin
      *ngIf="legendVisible"
      [cross]="true"
      [legendType]="'user'"
    ></app-legend-user-admin>
    <app-rights-procedure-dialog *ngIf="rights"></app-rights-procedure-dialog>
    <app-pa-user *ngIf="paVisible"></app-pa-user>

    <div *ngIf="added || edited" class="container-success">
      <div mat-dialog-title fxLayout="row-reverse" fxLayoutAlign="space-between center" class="header-success">
        <a *ngIf="cross" atlineLink (click)="close()" class="close"></a>
        <p *ngIf="added" class="text-bl">
          {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_ADD_SUCCESS' | translate }}
        </p>
        <p *ngIf="added == false" class="text-bl">
          {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_EDIT_SUCCESS' | translate }}
        </p>
      </div>

    </div>


    <div *ngIf="!legendVisible && !paVisible && !rights" class="body">
      <header fxLayoutAlign="space-between center">
        <label class="label-list">
          {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.LIST' | translate }}
        </label>
        <div fxLayout="row" fxLayoutAlign="start center" mat-icon-button>
          <button mat-icon-button (click)="legendVisibility()">
            <mat-icon svgIcon="info" class="mat-icon-legend"></mat-icon>
            <label class="legend">
              {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.LEGEND' | translate }}
            </label>
          </button>
        </div>
        <label class="nbUsers">
          {{
            'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.NB_USER_LABEL'
              | translate: { nbUsers: nbUsers, plurial: getPlurial(nbUsers) }
          }}
        </label>
      </header>
      <div *ngIf="isLoading" class="loading-spinner">
        <mat-spinner [diameter]="80" color="primary"></mat-spinner>
      </div>

      <div *ngIf="!isLoading">
        <table class="table">
          <thead>
            <tr>
              <th fxFlex="20" (click)="sortUsers('firstName')">
                {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.FIRST_NAME' | translate }}
                <mat-icon *ngIf="sortedColumn === 'firstName'">{{ sortAscending ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
              </th>
              <th fxFlex="19.5" (click)="sortUsers('lastName')">
                {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.LAST_NAME' | translate }}
                <mat-icon *ngIf="sortedColumn === 'lastName'">{{ sortAscending ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
              </th>
              <th fxFlex="21" (click)="sortUsers('login')">
                {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.TABLE.LOGIN' | translate }}
                <mat-icon *ngIf="sortedColumn === 'login'">{{ sortAscending ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
              </th>
              <th fxFlex="9.5">
                <div class="icon-number">
                  {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.DROITS' | translate }}
                </div>
              </th>
              <th fxFlex="10">
                <div *ngIf = "hasGroups" class="icon-number">
                  {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.GROUP' | translate }}
                </div>
              </th>
               <th fxFlex="9.5">
                <div *ngIf = "hasPas" class="icon-number">
                  <div>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.PA' | translate }}</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="filteredUsers.length === 0">
              <td colspan="4" class="no-users-found">
                <div class="no-users-message">
                  {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.EMPTY_USR' | translate }}
                </div>
              </td>
            </tr>
            <tr
              *ngFor="let user of filteredUsers"
              [ngClass]="{ admin: user.isAdmin }"
            >
              <td class="user-label" fxFlex="70">{{ user.firstName }}</td>
              <td class="user-label" fxFlex="70">{{ user.lastName }}</td>
              <td class="user-label" fxFlex="70">{{ user.login }}</td>
              <td fxFlex="10">
                <div class="icon-number">
                  <button mat-icon-button>
                    <mat-icon svgIcon="eye" (click)="openUserRights(user)"></mat-icon>
                  </button>
                  <span class="number">{{ user.rights }}</span>
                  <!-- <span class="number">{{ user.rights?.count}}</span> -->
                </div>
              </td>
              <td fxFlex="10">
                <div *ngIf = "hasGroups" class="icon-number">
                  <button mat-icon-button (click)="openUserGroups(user, this)" style="cursor: pointer;">
                    <mat-icon svgIcon="group"></mat-icon>
                  </button>
                  <span class="number">
                    <ng-container *ngIf="user.sup_dce; else groupCount">S</ng-container>
                    <ng-template #groupCount>{{ user.groups?.length }}</ng-template>
                  </span>
                </div>
              </td>
              <td fxFlex="10">
                <div *ngIf = "hasPas" class="icon-number" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <button class="button" (click)="openAssingUserPaDialog(user, this)">
                    <mat-icon>PA</mat-icon>
                  </button>
                  <span class="number">{{ countPa(user) }}</span>
                </div>
              </td>
              <td fxFlex="10">
                <button mat-icon-button (click)="openFormUserDialog(user, false, users , this)">
                  <mat-icon svgIcon="editpencil"></mat-icon>
                </button>
                <button *ngIf="!user.isAdmin" mat-icon-button>
                  <mat-icon svgIcon="bin" (click)="deleteUser(user)"></mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-administration-wapper>
