import { Injectable, NgZone } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { environment } from 'src/environments/environment';
import { UpdateUserProfileRequest } from '../models/api/requests/adminUser.request';
import { AdminUserService } from './adminUser.service';
import { UtilsService } from './utils.service';
import { SessionStorage } from '@atline-shared/enums/session-storage.enum';


@Injectable({
  providedIn: 'root'
})
export class SseService {
  private eventSource: EventSource | null = null;
  private token: string | null = null;
  private reconnectInterval = 5000; 

  constructor(private authService: AuthenticateService, 
    private adminUserService: AdminUserService,
    private readonly utilsService: UtilsService,
  
    private zone: NgZone) {}

  connect(userId: string, token: string) {
    this.token = token;
    const apiUrl = environment.api.replace(/\/$/, ''); // Remove trailing slash
    if(!userId){
      const userSession = this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true);
      userId = userSession.profile.user.id_ident; 
      console.log("lolalaodldfdlsfkl : ",userId);

    }
    const url = `${apiUrl}/sse?userId=${userId}&token=${encodeURIComponent(token)}`;
    this.initializeSSE(url);
  }

  private initializeSSE(url: string) {
    if (this.eventSource) {
      this.eventSource.close(); 
    }

    this.eventSource = new EventSource(url);

    this.eventSource.onopen = () => {
    };

    this.eventSource.addEventListener('user_change', (event) => { 
      this.zone.run(() => {
        // const eventData = JSON.parse(event.data);
        const messageEvent = event as MessageEvent;
        const eventData = JSON.parse(messageEvent.data);
        
        if (eventData.message.includes("Your profile recieved changes from admin")) {
          
          if (this.token) {
            this.authService.getTokenInfo(this.token).subscribe((res) => {
              sessionStorage.setItem('ATS:session', JSON.stringify(res)); 
              const req = new UpdateUserProfileRequest();
              req.cle_etab = res.profile.user.cle_etab;
              req.cle_utilisateur = res.profile.user.id_ident;
              req.contexte = 'reset';

              this.adminUserService.updateUserProfile(req).subscribe({
                next: () => {
                },
                error: () => {
                }
              });
            });
          }
        }
      });
    });

    this.eventSource.onerror = () => {
      this.eventSource?.close();
      setTimeout(() => this.initializeSSE(url), this.reconnectInterval);
    };
  }

  close() {
    this.eventSource?.close();
  }
}
