import { Injectable } from "@angular/core";
import { RoutingEnum } from "@atline-shared/enums/routing.enum";
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})
export class RedirectMsV3Helper {


    getSealTab(etat: 'crea' | 'traite' | 'pub' | 'err_boamp' = 'crea') {
        return `seal|liste&etat=${etat}`;
    }

    get newConsultation() {
        return 'fullweb|liste'
    }

    get inProgressConsultation() {
        return 'consultation|en_cours'
    }

    get moduleListDe() {
        return 'liste_donnees_essentielles';
    }

    private getCloturedConsultationTab(etat: 'cloture' | 'archive' | 'supprimee' = 'cloture') {

        const etatFromV3ToV2 = () => {
            switch (etat) {
                case 'cloture':
                    return 'cloturees'
                case 'archive':
                    return 'attribuees'
                case 'supprimee':
                    return 'supprimees'
                default:
                    return 'cloturees'
            }
        }
        return `consultation|archivees&param=${etatFromV3ToV2()}`;
    }

    private getRessourcesTab(etat: 'stats' | 'annuaire' | 'guichet-enregistrement' | 'liste-de' | 'liste-affaires' | 'externalisation-dce' | 'electro-websign' | 'visioneuse-dume' = 'stats') {        

        const getV2params = () => {
            switch (etat) {
                case 'stats':
                    return 'stats';

                case 'externalisation-dce':
                    return 'externalisation';

                case 'visioneuse-dume':
                    return 'dume'

                case 'liste-affaires':
                    return 'affaire'

                default:
                    return;
            }
        }

        switch (etat) {
            case 'stats':
            case 'externalisation-dce':
            case 'visioneuse-dume':
            case 'liste-affaires':
                return `ressources|ressources&param=${getV2params()}`;

            case 'electro-websign':
                return 'ressources|websign_pdf';

            case 'guichet-enregistrement':
                return
            case 'annuaire':
                return 'ressources|ressources_annuaire';

            case 'liste-de':
                return 'liste_donnees_essentielles';

            default:
                return `ressources|ressources&param=${getV2params()}`;
        }
    }

    // private getAdminTabs(etat: 'gestion_utilisateurs') {

    //     const getParamsV2 = (etat) => {

    //     }

    //     return `admin|administration&af=${etat}`;
    // }

    private getDocumentationTab(etat: 'docs' | 'id_pa' | 'web' | 'eopp' | 'bdc' = 'docs') {
        return `mon_compte|mon_compte&params=${etat}`
    }

    private getClubMsTab(etat: 'suggestion' | 'formation' | 'faq' | 'club' | 'newsletter' = 'suggestion') {
        return `club_ms|club_ms&af${etat}`
    }

    v2ToV3(module: V2ModuleEnum, params: string) {
        const ressourcesParams = () => {
            switch (params) {
                case 'stats':
                    return RoutingEnum.STATS;

                case 'externalisation':
                    return RoutingEnum.EXTERNALISATION_DCE;

                case 'dume':
                    return RoutingEnum.DUME;

                case 'affaire':
                    return RoutingEnum.LIST_AFFAIRES

                default:
                    return RoutingEnum.STATS;
            }
        }

        const consultationsCloturedParams = () => {
            switch (params) {
                case 'cloturees':
                    return 'cloture';

                case 'attribuees':
                    return 'archive';

                case 'supprimees':
                    return 'supprimee';

                case 'deposer_marche_mois_p':
                    return 'cloture'

                default:
                    return 'cloture';
            }
        }

        const sealParams = () => {
            switch (params) {
                case 'crea':
                    return 'creations'
                case 'err_boamp':
                    return 'rejetees'
                case 'traite':
                    return 'traitement'
                case 'pub':
                    return 'publiees';
                default:
                    return 'creations'
            }
        }

        const adminParams = () => {
            switch (params) {
                case 'gestion_utilisateurs':
                    return RoutingEnum.ADM_USERS
                case 'gestion_groupes':
                    return RoutingEnum.ADM_GROUPS
                case 'certs':
                    return RoutingEnum.ADM_KEYS_ENCRYPT
                case 'gestion_dce':
                    return RoutingEnum.ADM_CONSULTATION_MANAGMENT;
                case 'configuration':
                    return RoutingEnum.ADM_CONFIG;
                case 'configuration_lrar':
                    return RoutingEnum.ADM_CONFIG_ELRAR;
                case 'msg_pa_oe':
                    return RoutingEnum.ADM_OE_MESSAGE;
                case 'configuration_guichet':
                    return RoutingEnum.GUICHET_ENREGISTREMEMENT;
                default:
                    return RoutingEnum.ADM_USERS
            }
        }

        const o = {
            [V2ModuleEnum.ANNUAIRE]: [RoutingEnum.PA, RoutingEnum.RESSOURCES, RoutingEnum.ANNUAIRE],
            [V2ModuleEnum.CLUB_MS]: [],
            [V2ModuleEnum.CONSULTATION]: [RoutingEnum.PA, RoutingEnum.CONSULTATION, 'en_cours'],
            [V2ModuleEnum.CONSULTATION_CLOSED]: [RoutingEnum.PA, RoutingEnum.CONSULTATION, consultationsCloturedParams()],
            [V2ModuleEnum.DE]: [RoutingEnum.PA, RoutingEnum.RESSOURCES, RoutingEnum.LIST_DE],
            [V2ModuleEnum.FULL_WEB]: [RoutingEnum.PA, RoutingEnum.CONSULTATION, 'creer'],
            [V2ModuleEnum.MY_ACCOUNT]: [],
            [V2ModuleEnum.RESSOURCES]: [RoutingEnum.PA, RoutingEnum.RESSOURCES, ressourcesParams()],
            [V2ModuleEnum.SEAL]: [RoutingEnum.PA, RoutingEnum.ANNNOUNCE, sealParams()],
            [V2ModuleEnum.WEBSIGN]: [RoutingEnum.CERTIFICAT_SIGNAUTRE_MAIN],
            [V2ModuleEnum.ADMIN]: [RoutingEnum.PA, RoutingEnum.ADMINISTRATION, adminParams()],
            [V2ModuleEnum.CONFIG_EOP]: [],
            [V2ModuleEnum.CERTIFICATS]: [[RoutingEnum.CERTIFICAT_SIGNAUTRE_MAIN]],
            [V2ModuleEnum.DE_OE]: [[RoutingEnum.DE]],
        }
        return o[module];
    }

    v3ToV2(module: V3ModuleEnum, params: string | undefined, token: string | null) {        

        const consultationModule = () => {
            switch (params) {
                case 'creer':
                    return V2ModuleEnum.FULL_WEB;
                case 'en_cours':
                    return V2ModuleEnum.CONSULTATION;
                case 'cloture':
                case 'archive':
                case 'supprimee':
                    return this.getCloturedConsultationTab(params);
                // return V2ModuleEnum.CONSULTATION_CLOSED;

                default:
                    return V2ModuleEnum.CONSULTATION;
            }
        }

        const administrationModule = () => {
            switch (params) {
                case 'utilisateurs':
                    return 'gestion_utilisateurs';

                case 'groupes':
                    return 'gestion_groupes'

                case 'configuration':
                    return 'configuration'

                case 'configuration-elrar':
                    return 'configuration_lrar'

                case 'messages-informatifs-oe':
                    return 'msg_pa_oe'

                case 'guichet-enregistrement':
                    return 'configuration_guichet'

                default:
                    return 'gestion_utilisateurs'
            }
        }

        const o = {
            [V3ModuleEnum.SEAL]: `${token}&targetModule=${V2ModuleEnum.SEAL}`,
            [V3ModuleEnum.GESTION]: `${token}&targetModule=gestion`,
            [V3ModuleEnum.CONSULTATION]: `${token}&targetModule=${consultationModule()}`,
            [V3ModuleEnum.LIST_DE]: `${token}&targetModule=${this.moduleListDe}`,
            [V3ModuleEnum.MY_ACCOUNT]: `${token}&targetModule=${this.getDocumentationTab()}`,
            [V3ModuleEnum.RESSOURCES]: `${token}&targetModule=${this.getRessourcesTab(params as any)}`,
            [V3ModuleEnum.WEBSIGN]: `${token}&targetModule=${V2ModuleEnum.WEBSIGN}`,
            [V3ModuleEnum.CLUB_MS]: `${token}&targetModule=${this.getClubMsTab()}`,
            [V3ModuleEnum.DE]: `${token}&targetModule=${V2ModuleEnum.DE_OE}`,
            [V3ModuleEnum.ADMINISTRATION]: `${token}&targetModule=${V2ModuleEnum.ADMIN}&af=${administrationModule()}`,
        }

        return o[module] ?? token;
    }

}

export enum V2ModuleEnum {
    SEAL = 'seal|liste',
    FULL_WEB = 'fullweb|liste',
    CONSULTATION = 'consultation|en_cours',
    CONSULTATION_CLOSED = 'consultation|archivees',
    RESSOURCES = 'ressources|ressources',
    WEBSIGN = 'ressources|websign_pdf',
    ANNUAIRE = 'ressources|ressources_annuaire',
    DE = 'liste_donnees_essentielles',
    MY_ACCOUNT = 'mon_compte|mon_compte',
    CLUB_MS = 'club_ms|club_ms',
    ADMIN = 'admin|administration',
    CONFIG_EOP = 'admin|administration_eopp',
    CERTIFICATS = 'certs',
    DE_OE = 'an_prec'
}

export enum V3ModuleEnum {
    SEAL = 'joue-BOAMP-JAL',
    CONSULTATION = 'consultation',
    RESSOURCES = 'ressources',
    WEBSIGN = 'certificat-signature',
    LIST_DE = 'liste-de',
    MY_ACCOUNT = 'documentation',
    CLUB_MS = 'club_ms|club_ms',
    DE = 'donnees-essentielles',
    ADMINISTRATION = 'administration',
    GESTION = 'gestion'
}