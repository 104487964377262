import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { Icons } from '@atline-shared/enums/icons.enum';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

  public cguForm!: FormGroup;
  public acceptCGU = false;
  public typeResponse = new FormControl('', [Validators.required]);
  public isDume = false;

  icons = Icons;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CguComponent>,
    public dialogService: DialogsService,
    @Inject(MAT_DIALOG_DATA) public data: { isDume: 0 | 1 },

  ) { data.isDume = 0 }

  ngOnInit(): void {
    this.cguForm = this.formBuilder.group({
      typeResponse: this.typeResponse
    });

    if (!this.isDume) {
      this.typeResponse.setValue(1);
      this.cguForm.updateValueAndValidity();
    }
  }

  startDepot(): void {
    if (this.cguForm.valid && this.acceptCGU) { this.dialogRef.close(true) }
  }

  public getAcceptCGU(event: any): void {
    this.acceptCGU = event;
  }

}
